<template>
  <div id="eligible-items">
    <div class="container">
      <h1>{{ t('TAI_SAN_CHAP_NHAN') }}</h1>
      <p>{{ t('CAMDONHANH_CHAP_NHAN_HAU_HET_CAC_LOAI_TAI_SAN') }}</p>

      <div class="row items-list">
        <div v-for="item in eligibleItems" :key="item.name" class="col-12 col-md-6 col-lg-3">
          <div class="item">
            <div class="img-holder">
              <router-link :to="!!item.content ? applyParams(PATHS.ELIGIBLE_ITEM, item.slug) : '/'">
                <img :src="item.thumbnail" :alt="item.name" />
              </router-link>
            </div>

            <div class="text">
              <router-link :to="!!item.content ? applyParams(PATHS.ELIGIBLE_ITEM, item.slug) : '/'">
                <h3>{{ item.name }}</h3>
              </router-link>
              <p>{{ item.summary }}</p>
            </div>
          </div>
        </div>
      </div>

      <router-link :to="PATHS.SUBMIT_ORDER">
        <primary-button :text="t('CAM_NGAY!')" inverse />
      </router-link>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { applyParams } from '@/helpers';
import PATHS from '@/consts/paths';

import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'EligibleItems',
  components: {
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const eligibleItems = computed(() => store.state.eligibleItem.items);

    onMounted(async () => {
      await store.dispatch('eligibleItem/getItems');
    });

    return {
      t,
      eligibleItems,
      applyParams,
      PATHS
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#eligible-items {
  padding: 90px 0;
  text-align: center;

  h1 {
    color: $geranium;
    font-weight: 700;
  }

  .items-list {
    margin-top: 80px;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }

    .item {
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      margin-bottom: 20px;
      height: calc(100% - 20px);

      .img-holder {
        width: 100%;
        height: 0;
        padding-bottom: 80%;
        margin-bottom: 15px;
        box-sizing: border-box;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        padding: 15px;

        h3 {
          font-size: 20px;
          font-weight: 700;
        }
      }

      @media (max-width: 767px) {
        padding: 12px;

        .img-holder {
          width: 90px;
          height: 90px;
          padding: 0;
          margin: 0;
          margin-right: 12px;
          display: inline-block;
          vertical-align: middle;

          img {
            object-fit: cover;
          }
        }

        .text {
          padding: 0;
          width: calc(100% - 90px - 12px);
          display: inline-block;
          vertical-align: middle;
          text-align: left;
          font-size: 14px;

          h3 {
            font-size: 18px;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
