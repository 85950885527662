import eligibleItemService from '@/services/eligibleItem';

const actions = {
  async getItems({ commit, state }) {
    if (state.items && state.items.length) return;

    const items = await eligibleItemService.getItems();
    commit('setItems', items);
  },
};

export default actions;
