<template>
  <div
    class="check-input input"
    :class="{
      disabled,
      active: value,
    }"
  >
    <div class="check" @click="toggle">
      <div class="outer"></div>
      <div class="inner">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
      </div>
    </div>
    <slot></slot>
    <div class="text">{{ text }} <span v-if="required">*</span></div>
    <div v-if="!!validateMessage" class="red">{{ validateMessage }}</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'RadioInput',
  components: {},
  props: {
    text: String,
    value: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const isValid = ref(false);
    const validateMessage = ref('');
    const cacheValue = ref(props.refValue);

    const wrappedValue = computed({
      get() { return cacheValue.value; },
      set(value) {
        cacheValue.value = value;
        emit('update:value', value);
      }
    });

    const toggle = () => {
      wrappedValue.value = !wrappedValue.value;
    };

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const validateInput = () => {
      const val = wrappedValue.value;

      if ((props.required) && !val) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      alert('');
      return true;
    };

    watch(props, (newProps) => {
      cacheValue.value = newProps.value;
    });

    watch(wrappedValue, () => {
      isValid.value = validateInput();
    });

    return {
      isValid,
      wrappedValue,
      toggle,
      validateMessage,
      validateInput
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';
@import '~@/assets/scss/dimens';

.check-input {
  display: inline-block;
  position: relative;

  .check {
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    .outer {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      background-color: $white;
      border: 1px solid $gainsboro;
      border-radius: 4px;
      transition: all .2s ease-in-out;
    }

    .inner {
      position: absolute;
      top: -6px;
      left: 3px;
      width: 11px;
      height: 11px;
      color: $raisin;
      display: none;
      transition: all .2s ease-in-out;

      svg {
        path, polygon {
          fill: currentColor;
        }
      }
    }
  }

  .text {
    display: inline-block;
    vertical-align: middle;
  }

  .red {
    color: $redSalsa;
    font-size: 12px;
  }

  &.disabled {
    pointer-events: none;
    color: $grey;
  }

  &.active {
    .check {
      .outer {
        background-color: $redSalsa;
      }

      .inner {
        display: block;
        color: $white;
      }
    }
  }
}
</style>
