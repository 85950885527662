import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';
import store from '@/store';

export default {
  async createOrder(data) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: '/v1/orders/',
      method: HttpMethod.POST
    };

    const headers = {
      'X-Api-Key': process.env.VUE_APP_SITE_API_KEY,
      'Content-Type': 'application/json'
    };

    const { source, medium, campaign } = store.state.app.params;

    try {
      const res = await processRequest(ep, { headers, data: { ...data, source, medium, campaign } });
      const order = processSuccessResponse(res);

      if (process.browser && window.fbq) {
        window.fbq('track', 'Lead');
      }

      return order;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateOrder(orderId, data) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/orders/${orderId}/`,
      method: HttpMethod.PATCH
    };

    const headers = {
      'X-Api-Key': process.env.VUE_APP_SITE_API_KEY,
      'Content-Type': 'application/json'
    };

    try {
      const res = await processRequest(ep, { headers, data });
      const order = processSuccessResponse(res);

      return order;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
