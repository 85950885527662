const mutations = {
  setSize(state, w) {
    const breakpoint = 768;
    const breakpointTablet = 1200;

    state.w = w;

    if (w < breakpoint) {
      state.isMobile = true;
      state.isTablet = false;
      state.isDesktop = false;
    } else if ((w >= breakpoint) && (w < breakpointTablet)) {
      state.isMobile = false;
      state.isTablet = true;
      state.isDesktop = false;
    } else if (w >= breakpointTablet) {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = true;
    }
  },

  setLocale(state, locale) {
    state.locale = locale;
  },

  setParams(state, params) {
    state.params = params;
  },

  addDiscreteContent(state, discreteContent) {
    state.discreteContent[discreteContent.key] = discreteContent.content;
  },
};

export default mutations;
