<template>
  <div id="assurance">
    <div class="container">
      <div class="row">
        <div class="col col-12 col-lg-7">
          <template v-if="!assuranceContent">
            <p>{{ t('LUON_AN_TAM') }}</p>
            <ul>
              <li v-for="item in features" :key="item">
                <div class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM14.78 7.7L9.11 13.37C8.97 13.51 8.78 13.59 8.58 13.59C8.38 13.59 8.19 13.51 8.05 13.37L5.22 10.54C4.93 10.25 4.93 9.77 5.22 9.48C5.51 9.19 5.99 9.19 6.28 9.48L8.58 11.78L13.72 6.64C14.01 6.35 14.49 6.35 14.78 6.64C15.07 6.93 15.07 7.4 14.78 7.7Z" fill="#F9B506"/>
                  </svg>
                </div>
                <div class="text">{{ t(item) }}</div>
              </li>
            </ul>
            <p v-if="($i18n.locale === 'vi')">Ví dụ: Khách hàng cầm cố tài sản 10.000.000 đồng trong 1 năm. Lãi suất 1 năm phải trả từ 1.800.000 đồng. Tổng số tiền phải trả sau 1 năm sẽ từ 11.800.000 đồng. Bạn có thể chọn trả lãi hàng tháng, không bắt buộc trả nợ gốc hàng tháng. Lãi suất được tính linh hoạt theo thời gian thực vay, không có phí phạt nếu trả nợ trước hạn.</p>
          </template>
          <template v-else>
            <div v-html="assuranceContent" />
          </template>
        </div>
        <div class="col col-12 col-lg-5 shield">
          <img :src="shield" :alt="t('NIEM_PHONG_VA_BAO_HIEM')" />
          <p><span>{{ t('NIEM_PHONG_VA_BAO_HIEM') }}</span><br/><span class="yellow">{{ t('150%_GIA_TRI') }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import shield from '@/assets/imgs/shield.png';

const features_ = [
  'LAI_SUAT_VAY_TRONG_THOI_HAN_CHI_12%',
  'THOI_HAN_KHOAN_VAY_LINH_HOAT',
  'THOI_GIAN_THANH_TOAN_LINH_HOAT',
  'MIEN_PHI_BAO_HIEM_RUI_RO',
];

export default {
  name: 'Assurance',
  components: {
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const store = useStore();

    const assuranceContent = computed(() => store.state.app.discreteContent.ASSURANCE);

    const routeName = computed(() => route.name);

    const features = computed(() => {
      if (locale !== 'vi') return features_;

      return ([
        'Khoản vay linh hoạt từ 3-12 tháng',
        'Lãi suất vay trong hạn chỉ từ 18%/năm',
        'Tài sản được đảm bảo an toàn 100%',
        'Thời hạn vay linh hoạt, có thể trả trước hạn - không phí phạt'
      ]);
    });

    return {
      t,
      features,
      shield,
      routeName,
      assuranceContent
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#assurance {
  padding: 90px 0;
  text-align: left;
  background-color: $raisin;
  color: $gainsboro;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  ul {
    padding: 0;
    margin: 15px 0;
    list-style: none;
    font-weight: 700;

    li {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;

      .icon {
        margin-right: 10px;
      }
    }
  }

  .shield {
    text-align: center;

    img {
      width: 300px;
    }

    p {
      font-weight: 700;

      .yellow {
        color: $selectiveYellow;
      }
    }
  }
}
</style>
