const mutations = {
  setActiveStatus(state, { val, id }) {
    const q = [...state.queue];
    let v = val;

    if (id) {
      const i = q.indexOf(id);
      if (i >= 0) {
        if (!val) {
          q.splice(i, 1);
        }
      } else {
        if (val) {
          q.push(id);
        }
      }
    }

    if (!val && q.length <= 0) {
      v = false;
    } else {
      v = true;
    }

    state.isActive = v;
    state.queue = q;
  }
};

export default mutations;
