<template>
  <div id="home-banner">
    <div class="banner-text">
      <div class="container">
        <div class="text-holder">
          <h1>
            {{ t('CAN_TIEN_MAT_NGAY?') }}
            <div class="curve">
              <svg width="337" height="14" viewBox="0 0 337 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10.7918C96.8717 -5.49325 247.842 0.855177 336 13" stroke="white" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>
          </h1>
          <h2>{{ t('HAY_MANG_TAI_SAN_CUA_BAN_DEN_VOI_CHUNG_TOI!') }}</h2>
          <router-link :to="PATHS.SUBMIT_ORDER">
            <primary-button :text="t('CAM_NGAY!')" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="banner-image">
      <img :src="bannerImage" alt="Camdonhanh" />
    </div>

    <div class="banner-nav">
      <div class="container">
        <div class="nav-outer-wrap">
          <div class="nav-holder">
            <div class="arrow" />
            <ul>
              <li><a href="#how-we-work">{{ t('QUY_TRINH_CAM_DO') }}</a></li>
              <li><a href="#eligible-items">{{ t('TAI_SAN_CHAP_NHAN') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

import PATHS from '@/consts/paths';

import PrimaryButton from '@/components/PrimaryButton';

import bannerImage from '@/assets/imgs/banner-image.png';

export default {
  name: 'HomeBanner',
  components: {
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      bannerImage,
      PATHS
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#home-banner {
  width: 100vw;
  overflow-x: hidden;
  height: calc(100vh - #{$headerHeight});
  background: linear-gradient(266.36deg, #E11F27 -5.07%, #A2161B 51.3%);
  position: relative;

  .banner-text {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: $white;

    .text-holder {
      width: 420px;
    }

    h1 {
      font-weight: 700;
      position: relative;

      .curve {
        position: absolute;
        bottom: -40px;
        left: 30px;
      }
    }

    h2 {
      font-weight: 700;
      margin-top: 50px;
      font-size: 28px;
      line-height: 48px;
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      top: 90px;
      transform: none;
      text-align: center;

      .text-holder {
        width: 100%;
      }

      h1 {
        .curve {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      h2 {
        font-size: 20px;
        line-height: 28px;
        padding: 0 30px;
      }
    }
  }

  .banner-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;

    img {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .banner-nav {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;

    @media (max-width: 767px) {
      bottom: auto;
      top: calc(50% + 100px);
      transform: translateY(-50%);
    }

    .nav-outer-wrap {
      position: relative;

      .nav-holder {
        position: absolute;
        bottom: 0;
        left: 0;

        @media (max-width: 767px) {
          bottom: auto;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        .arrow {
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: $white;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) rotate(-135deg);
            width: 8px;
            height: 8px;
            border-top: 1px solid $white;
            border-left: 1px solid $white;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding-left: 20px;
          color: $white;
          font-weight: 700;

          li {
            margin: 15px 0;
            transition: all .2s ease-in-out;

            &:hover {
              color: $candyPink;
              cursor: pointer;
            }

            a {
              color: inherit;
            }

            @media (max-width: 767px) {
              text-shadow: 0px 1px 1px $shadow;
            }
          }
        }
      }
    }
  }
}
</style>
