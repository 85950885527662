<template>
  <div id="how-we-work">
    <div class="container">
      <h1>{{ t('QUY_TRINH_CAM_DO') }}</h1>

      <div class="steps" v-if="isDesktop">
        <div v-for="(item, i) in stepItems" :key="item.text" class="step">
          <step-item :item="item" :index="i" :count="stepItems.length" />
        </div>
      </div>
      <div class="steps" v-else>
        <slick :options="slickOptions" ref="slickRef">
          <div v-for="(item, i) in stepItems" :key="item.text" class="step slide">
            <step-item :item="item" :index="i" :count="stepItems.length" />
          </div>
        </slick>

        <div class="arrows" v-if="!isDesktop">
          <div class="arrow left" ref="arrowLeft">
            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.20504 1.41L6.79504 0L0.795044 6L6.79504 12L8.20504 10.59L3.62504 6L8.20504 1.41Z" fill="#2E2E38"/>
            </svg>
          </div>

          <div class="arrow right" ref="arrowRight">
            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.20504 0L0.795044 1.41L5.37504 6L0.795044 10.59L2.20504 12L8.20504 6L2.20504 0Z" fill="#2E2E38"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import Slick from '@/components/Slick';

import step1 from '@/assets/imgs/how-we-work/step1-min.png';
import step2 from '@/assets/imgs/how-we-work/step2-min.png';
import step3 from '@/assets/imgs/how-we-work/step3-min.png';
import step4 from '@/assets/imgs/how-we-work/step4-min.png';

import StepItem from './StepItem';

const stepItems = [
  {
    img: step1,
    text: 'GUI_THONG_TIN_TAI_SAN'
  },
  {
    img: step2,
    text: 'NHAN_DINH_GIA_MIEN_PHI'
  },
  {
    img: step3,
    text: 'THAM_DINH_TAI_SAN_&_NHAN_TIEN'
  },
  {
    img: step4,
    text: 'HOAN_TRA_KHOAN_VAY_&_NHAN_LAI_TAI_SAN'
  }
];

export default {
  name: 'HowWeWork',
  components: {
    Slick,
    StepItem
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const arrowLeft = ref(null);
    const arrowRight = ref(null);

    const isDesktop = computed(() => store.state.app.isDesktop);

    const slickOptions = computed(() => ({
      arrows: true,
      autoplay: false,
      infinite: false,
      slidesToShow: 1,
      speed: 200,
      cssEase: 'linear',
      centerMode: true,
      prevArrow: arrowLeft.value,
      nextArrow: arrowRight.value,
    }));

    return {
      t,
      stepItems,
      isDesktop,
      slickOptions,
      arrowLeft,
      arrowRight
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#how-we-work {
  padding: 90px 0;
  text-align: center;

  h1 {
    color: $geranium;
    font-weight: 700;
  }

  .steps {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-width: 767px) {
      display: block;
    }

    .arrows {
      display: flex;
      justify-content: space-around;
      width: 50%;
      margin: auto;

      .arrow {
        width: 25px;
      }
    }

    .step {
      width: 200px;
      text-align: center;
      position: relative;

      @media (max-width: 767px) {
        margin-right: 15px;
      }

      .img-holder {
        width: 150px;
        display: inline-block;

        img {
          width: 100%;
        }
      }

      .text {
        background-color: $snow;
        padding: 15px;
        padding-bottom: 20px;
        border-radius: 8px;

        h2 {
          color: $raisin;
          margin-bottom: 15px;
          font-weight: 700;
        }

        p {
          margin-bottom: 0;
        }
      }

      .arrow {
        position: absolute;
        top: 30px;
        left: 200px;
        width: #{(1320 - (200 * 4)) / 3}px;

        @media (max-width: 1400px) {
          width: #{(1170 - (200 * 4)) / 3}px;
        }

        svg {
          width: 100%;
        }

        &.flip {
          transform: scale(1, -1);
          top: 100px;
        }
      }
    }
  }
}
</style>
