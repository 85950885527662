<template>
  <div class="slick-holder" ref="holder">
    <slot></slot>
  </div>
</template>

<script>
import { ref, watch, computed, onUnmounted } from 'vue';
import $ from 'jquery';

import 'slick-carousel/slick/slick.min';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
  name: 'Slick',
  props: {
    options: {
      type: Object,
      default: () => {}
    },
  },
  setup(props, { emit }) {
    const holder = ref(null);
    const slickOptions = computed(() => props.options);

    const play = () => {
      $(holder.value).slick('slickPlay');
    };

    const pause = () => {
      $(holder.value).slick('slickPause');
    };

    const goTo = (index) => {
      $(holder.value).slick('slickGoTo', index);
    };

    const next = (index) => {
      $(holder.value).slick('slickNext', index);
    };

    const prev = (index) => {
      $(holder.value).slick('slickPrev', index);
    };

    watch([slickOptions, holder], ([opts, elem]) => {
      if (!elem || !opts) return;

      if ($(elem).hasClass('slick-initialized')) {
        $(elem).slick('unslick');
      }

      $(elem).slick(opts);
      $(elem).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        emit('beforeChange', currentSlide, nextSlide);
      });
    }, {
      immediate: true
    });

    onUnmounted(() => {
      $(holder.value).slick('unslick');
    });

    return {
      holder,
      play,
      pause,
      goTo,
      next,
      prev
    };
  }
};
</script>

<style lang="scss">
.slick-holder {
  width: 100%;
}
</style>
