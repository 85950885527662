import ERROR_CODES from '@/consts/errorCodes';

export default class InvalidResponse extends Error {
  static code = ERROR_CODES.INVALID_RESPONSE;

  constructor(message) {
    super(message || 'Invalid response.');
    this.code = ERROR_CODES.INVALID_RESPONSE;
  }
}
