import { createRouter, createWebHistory } from 'vue-router';

import { scrollToTop } from '@/helpers';
import PATHS from '@/consts/paths';

import Home from './views/Home';
import AboutUs from './views/AboutUs';
import Contacts from './views/Contacts';
import EligibleItemDetail from './views/EligibleItemDetail';
import SubmitOrder from './views/SubmitOrder';
import BlogList from './views/BlogList';
import BlogContent from './views/BlogContent';

const routes = [
  { path: '/:catchAll(.*)', redirect: PATHS.HOME },
  {
    path: PATHS.HOME,
    component: Home,
    name: 'home'
  },
  {
    path: PATHS.ABOUT,
    component: AboutUs,
    name: 'about-us'
  },
  {
    path: PATHS.CONTACTS,
    component: Contacts,
    name: 'contacts'
  },
  {
    path: PATHS.ELIGIBLE_ITEM,
    component: EligibleItemDetail,
    name: 'eligible-item-detail'
  },
  {
    path: PATHS.SUBMIT_ORDER,
    component: SubmitOrder,
    name: 'submit-order',
    props: true
  },
  {
    path: PATHS.BLOG,
    component: BlogList,
    name: 'blog-list'
  },
  {
    path: PATHS.BLOG_CONTENT,
    component: BlogContent,
    name: 'blog-content'
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    if (to.name !== from.name) {
      scrollToTop();
    }

    return savedPosition;
  }
});

export default router;
