<template>
  <div id="submit-request" ref="wrapperRef">
    <div class="coins">
      <img v-for="(coin, i) in coins" :key="coin" :src="coin" :width="getRandomCoinSize()" :class="`coin-${(i + 1)}`" ref="coinRefs" />
    </div>

    <div class="container">
      <h1>{{ t('BAN_CO_YEU_CAU_CAM_CO_TAI_SAN_GIA_TRI_CAO?') }}</h1>

      <div class="row">
        <div class="col col-12 col-md-6">
          <text-input :label="t('TEN_CUA_BAN')" :placeholder="t('NHAP_TEN_CUA_BAN')" v-model:value="name" ref="nameInputRef" required />
        </div>
        <div class="col col-12 col-md-6 amount">
          <text-input :label="t('SO_TIEN_MONG_MUON')" :placeholder="t('NHAP_SO_TIEN_BAN_MONG_MUON')" v-model:value="amount" ref="amountInputRef" :format="formatAmount" required />
          <div class="currency">VNĐ</div>
        </div>
        <div class="col col-12 col-md-6">
          <text-input :label="t('DIEN_THOAI_LIEN_HE')" :placeholder="t('NHAP_SO_DIEN_THOAI_LIEN_HE')" v-model:value="phoneNumber" ref="phoneNumberInputRef" type="phone" required />
        </div>
        <div class="col col-12 col-md-6">
          <select-input :label="t('LOAI_TAI_SAN')" :placeholder="t('CHON_LOAI_TAI_SAN')" :items="assetCategories" v-model:value="assetCategory" ref="assetCategoryInputRef" required />
        </div>
      </div>

      <primary-button :text="t('CAM_NGAY')" inverse @click="submit()" />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { TimelineMax, Linear, TweenMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import * as ScrollMagic from 'scrollmagic';

import eligibleItems from '@/consts/eligibleItems';
import { addCommas, removeCommas } from '@/helpers';
import { MAX_AMOUNT } from '@/consts';
import PATHS from '@/consts/paths';

import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';
import SelectInput from '@/components/SelectInput';

import orderService from '@/services/order';

import coin1 from '@/assets/imgs/coins/coin-1-min.png';
import coin2 from '@/assets/imgs/coins/coin-2-min.png';
import coin3 from '@/assets/imgs/coins/coin-3-min.png';
import coin4 from '@/assets/imgs/coins/coin-4-min.png';
import coin5 from '@/assets/imgs/coins/coin-5-min.png';
import coin6 from '@/assets/imgs/coins/coin-6-min.png';
import coin7 from '@/assets/imgs/coins/coin-7-min.png';

const coins = [coin1, coin2, coin3, coin4, coin5, coin6, coin7];

ScrollMagicPluginGsap(ScrollMagic, TimelineMax);
const controller = new ScrollMagic.Controller();

export default {
  name: 'SubmitRequest',
  components: {
    TextInput,
    SelectInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const name = ref('');
    const amount = ref('');
    const phoneNumber = ref('');
    const assetCategory = ref(null);

    const nameInputRef = ref(null);
    const amountInputRef = ref(null);
    const phoneNumberInputRef = ref(null);
    const assetCategoryInputRef = ref(null);

    const coinRefs = ref([]);
    const wrapperRef = ref(null);

    const assetCategories = eligibleItems.map((item) => (
      {
        text: item.name,
        value: item.id
      }
    ));

    const isDesktop = computed(() => store.state.app.isDesktop);

    const randomRange = (min, max) => (Math.floor(Math.random() * (max - min + 1)) + min);

    const getRandomCoinSize = () => {
      const w = randomRange(isDesktop.value ? 50 : 30, isDesktop.value ? 80 : 60);
      return w;
    };

    const submit = async () => {
      const inputs = [
        nameInputRef.value,
        amountInputRef.value,
        phoneNumberInputRef.value,
        assetCategoryInputRef.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        try {
          let parsedAmount = removeCommas(amount.value, '.');
          parsedAmount = parseInt(parsedAmount, 10);
          if (parsedAmount > MAX_AMOUNT) parsedAmount = MAX_AMOUNT;

          const res = await orderService.createOrder({
            firstName: name.value,
            amount: parsedAmount,
            phoneNumber: phoneNumber.value,
            assetCategoryId: assetCategory.value,
          });

          router.push({ path: PATHS.SUBMIT_ORDER, query: { overrideStep: 3 } });
        } catch (e) {
          store.dispatch('flash/error', 'ERROR_OCCURRED_PLEASE_TRY_AGAIN');
        }
      }
    };

    const formatAmount = (val) => {
      let formatted = addCommas(val, '.');
      if (formatted === '0') formatted = '';

      return formatted;
    };

    watch([coinRefs, wrapperRef], (([coins_, wrapper]) => {
      if (!coins_?.length || !wrapper) return;

      const tl = new TimelineMax();

      coins_.forEach((item) => {
        const y = -randomRange(20, 30);
        const tweenc = TweenMax.to(item, 1, { y, ease: Linear.easeNone });
        tl.add(tweenc);
      });

      const scene = new ScrollMagic.Scene({
        triggerElement: wrapper,
        triggerHook: 1,
        duration: '100%'
      });

      scene.setTween(tl).addTo(controller);
    }));

    return {
      t,
      assetCategories,
      name,
      amount,
      phoneNumber,
      assetCategory,
      coins,
      getRandomCoinSize,
      coinRefs,
      wrapperRef,

      nameInputRef,
      phoneNumberInputRef,
      amountInputRef,
      assetCategoryInputRef,

      submit,
      formatAmount
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#submit-request {
  width: 100%;
  background: linear-gradient(100.9deg, #E11F27 -4.08%, #A2161B 51.38%);
  position: relative;
  padding: 50px 0;
  color: $white;
  text-align: center;
  position: relative;

  .coins {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;

      &.coin-1 {
        bottom: -60px;
        left: 150px;
      }

      &.coin-2 {
        bottom: 30px;
        left: 50px;
      }

      &.coin-3 {
        top: 50%;
        right: 50px;
      }

      &.coin-4 {
        top: -20px;
        right: 100px;
      }

      &.coin-5 {
        bottom: -30px;
        right: 150px;
      }

      &.coin-6 {
        top: -20px;
        left: 100px;
      }

      &.coin-7 {
        top: calc(50% - 70px);
        left: -20px;
      }

      @media (max-width: 767px) {
        &.coin-1 {
          bottom: -60px;
          left: 75px;
        }

        &.coin-2 {
          bottom: 15px;
          left: 25px;
        }

        &.coin-3 {
          top: 25%;
          right: 25px;
        }

        &.coin-4 {
          top: -10px;
          right: 50px;
        }

        &.coin-5 {
          bottom: -15px;
          right: 75px;
        }

        &.coin-6 {
          top: 100px;
          left: 50px;
        }

        &.coin-7 {
          top: calc(50% - 35px);
          left: -10px;
        }
      }
    }
  }

  .container {
    max-width: 1170px;
    position: relative;
    z-index: 2;
  }

  h1 {
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
  }

  .input {
    width: 100%;
    margin-bottom: 30px;

    label {
      color: $white;

      span {
        color: $white;
      }
    }

    .red {
      color: $white;
    }
  }

  .amount {
    position: relative;

    .currency {
      position: absolute;
      top: 52px;
      right: 32px;
      color: $raisin;
      font-weight: 700;
    }
  }

  .primary-button {
    width: 50%;
    margin-top: 20px;

    @media (max-width: 767px) {
      width: 100%;
    }

    &::before {
      background: linear-gradient(100.9deg, #E11F27 -4.08%, #A2161B 51.38%);
    }
  }
}
</style>
