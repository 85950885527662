import discreteContentService from '@/services/discreteContent';

const actions = {
  setSize({ commit }, w) {
    commit('setSize', w);
  },

  setLocale({ commit }, locale) {
    commit('setLocale', locale);
  },

  setParams({ commit }, params) {
    commit('setParams', params);
  },

  async loadDiscreteContent({ commit }, key) {
    const discreteContent = await discreteContentService.readDiscreteContent(key);
    commit('addDiscreteContent', discreteContent);
  }
};

export default actions;
