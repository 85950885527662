<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }} | Camdonhanh</template>
  </metainfo>

  <app-header />

  <transition name="fade">
    <router-view />
  </transition>

  <app-footer />

  <loader />
  <flash-message />
  <facebook-pixel />
</template>

<script>
import { onMounted, onUnmounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useMeta, useActiveMeta } from 'vue-meta';
import { useRoute, useRouter } from 'vue-router';

import i18n from '@/translation';

import AppHeader from '@/components/Header';
import AppFooter from '@/components/Footer';
import Loader from '@/components/Loader';
import FlashMessage from '@/components/FlashMessage';
import FacebookPixel from '@/components/FacebookPixel';

import coverImg from '@/assets/imgs/cover-img.jpg';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Loader,
    FlashMessage,
    FacebookPixel
  },
  setup() {
    const title = 'Camdonhanh';
    const description = 'Cầm Đồ Nhanh dịch vụ cầm đồ uy tín, lãi suất thấp tại TPHCM, đưa cầm đồ trở thành một giải pháp tài chính hiện đại. Cầm ô tô, xe máy, túi xách, đồng hồ cao cấp, điện thoại.';
    const { meta } = useMeta({
      charset: 'utf8',
      title,
      description,
      og: {
        title,
        description,
        image: [coverImg],
        'image:width': 1200,
        'image:height': 628
      },
      htmlAttrs: {
        lang: ['vi']
      },
    });

    const metadata = useActiveMeta();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const locale = computed(() => store.state.app.locale);

    const query = computed(() => route.query);

    const watchScreenSize = () => {
      if (process.browser) {
        const w = window.innerWidth;
        store.dispatch('app/setSize', w);
      }
    };

    watch(locale, (val) => {
      i18n.global.locale = val;
    }, {
      immediate: true
    });

    onMounted(() => {
      store.dispatch('app/loadDiscreteContent', 'ASSURANCE');

      window.addEventListener('resize', watchScreenSize);
      watchScreenSize();

      router.isReady().then(() => {
        const { utm_source: utmSource, utm_medium: medium, utm_campaign: campaign } = route.query;
        let source = utmSource ? utmSource.toUpperCase() : 'WEBSITE';
        if (source === 'UNDEFINED') source = 'ONLINE';
        store.dispatch('app/setParams', { source, medium, campaign });

        if (route.name !== 'blog-list') {
          router.replace({ query: {} });
        }
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', watchScreenSize);
    });

    return {
      metadata,
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/fonts';
@import "~@/assets/scss/colors";
@import '~@/assets/scss/dimens';

body {
  font-family: "UTM Avo", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $raisin;
  margin: 0;
  overflow-x: hidden;
  padding-top: $headerHeight;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s ease-in-out;

  &:hover {
    color: $geranium;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
