<template>
  <header :class="{ active: menuActive }">
    <div class="container">
      <router-link :to="PATHS.HOME">
        <div class="logo-holder">
          <logo />
        </div>
        <logo-text />
      </router-link>
      <div class="right">
        <primary-button v-if="false" class="locale" @click="toggleLocale()" :text="locale" />
        <nav-menu ref="menuRef" />
      </div>
    </div>
  </header>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import PATHS from '@/consts/paths';

import Logo from '@/components/Logo';
import LogoText from '@/components/LogoText';
import PrimaryButton from '@/components/PrimaryButton';

import NavMenu from './NavMenu';

export default {
  name: 'Header',
  components: {
    Logo,
    LogoText,
    PrimaryButton,
    NavMenu
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();

    const menuRef = ref(null);

    const isDesktop = computed(() => store.state.app.isDesktop);

    const toggleLocale = () => {
      if (locale.value === 'en') {
        store.dispatch('app/setLocale', 'vi');
      } else {
        store.dispatch('app/setLocale', 'en');
      }
    };

    const isHome = computed(() => route.path === PATHS.HOME);
    const menuActive = computed(() => menuRef.value?.active);

    return {
      t,
      locale,
      toggleLocale,
      isDesktop,
      isHome,
      PATHS,
      menuRef,
      menuActive,
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $white;
  width: 100%;
  box-sizing: border-box;
  height: $headerHeight;
  box-shadow: 0px 0px 1px 1px $shadow;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
  }

  .logo-holder {
    width: 45px;
    height: 45px;
    background-color: $geranium;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;

    .logo {
      color: $white;
      width: 24px;
      margin-top: 8.5px;
      margin-left: 1px;
    }
  }

  .right {
    margin-left: auto;

    .locale {
      margin-top: 3px;
      text-transform: uppercase;
      width: 42px;
      height: 42px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      vertical-align: middle;
    }

    #nav-menu {
      vertical-align: middle;
      margin-left: 15px;
    }
  }
}
</style>
