<template>
  <div id="contacts-cover">
    <div class="bg">
      <div class="container shapes-wrapper">
        <div class="shape lines">
          <svg width="680" height="667" viewBox="0 0 680 667" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M97.2218 104.634C187.745 25.9016 367.18 -30.2935 443.85 67.858C514.936 158.86 412.255 185.747 449.906 284.555C487.558 383.363 654.127 361.743 673.321 471.018C684.462 534.444 634.613 602.532 544.822 629.249C455.031 655.966 325.3 641.311 176.677 539.252C36.2849 442.846 3.27449 345.626 11.9345 266.79C20.5946 187.954 70.9273 127.503 97.2218 104.634Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M178.774 528.551C49.8599 440.295 18.893 351.085 26.41 278.46C34.0087 206.326 79.6001 150.757 103.932 129.122C187.014 55.72 352.582 3.06332 425.684 91.6085C493.183 173.366 402.836 198.999 438.24 289.72C439.258 292.3 440.366 294.794 441.56 297.209C473.85 362.624 569.207 368.77 616.059 416.409C628.777 429.342 638.007 445.287 641.391 466.207C651.122 526.381 604.374 589.133 521.179 613.374C437.542 637.652 316.903 622.946 178.774 528.551Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M180.872 517.85C63.4355 437.744 34.5123 356.544 40.8858 290.128C47.4229 224.697 88.1967 173.945 110.643 153.611C186.028 85.316 337.96 36.4122 407.519 115.359C471.406 187.868 393.095 212.353 426.576 294.885C427.541 297.264 428.604 299.561 429.732 301.792C460.113 362.064 545.718 370.16 587.153 415.008C598.467 427.254 606.652 442.149 609.461 461.397C617.769 518.327 574.137 575.734 497.537 597.5C420.053 619.339 308.508 604.581 180.872 517.85Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M182.967 507.148C77.0082 435.191 49.9384 361.99 55.359 301.796C60.6489 243.054 96.8326 197.171 117.351 178.099C185.184 115.046 323.301 69.7533 389.351 139.109C449.593 202.366 383.537 225.653 414.909 300.049C415.827 302.225 416.839 304.328 417.9 306.374C446.374 361.503 522.221 371.566 558.244 413.605C568.153 425.168 575.282 439.014 577.528 456.586C584.392 510.282 543.897 562.334 473.892 581.625C402.562 601.025 300.11 586.216 182.967 507.148Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M185.064 496.446C90.4336 432.814 65.5023 367.446 69.8347 313.465C74.012 261.423 105.474 220.402 124.061 202.587C184.359 144.794 308.598 103.088 371.186 162.859C427.736 216.863 374.006 238.948 403.244 305.213C404.115 307.186 405.076 309.094 406.072 310.957C432.637 360.941 498.717 372.992 529.338 412.203C537.84 423.088 543.9 435.881 545.598 451.774C550.991 502.247 513.708 549.078 450.25 565.749C385.122 582.859 291.546 568.047 185.064 496.446Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M187.16 485.744C103.984 430.291 81.0708 372.905 84.3089 325.133C87.382 279.793 114.119 243.639 130.771 227.075C183.555 174.568 293.827 136.425 353.019 186.609C405.815 231.37 364.504 252.24 391.579 310.378C392.403 312.149 393.312 313.861 394.242 315.54C418.899 360.381 475.196 374.444 500.431 410.801C507.522 421.017 512.499 432.753 513.667 446.963C517.551 494.225 483.475 535.702 426.606 549.874C367.637 564.571 283.118 549.719 187.16 485.744Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M189.258 475.042C117.547 427.757 96.6521 378.366 98.7848 336.802C100.767 298.167 122.773 266.885 137.481 251.563C182.782 204.372 278.963 169.781 334.853 210.36C383.808 245.903 355.044 265.523 379.913 315.544C380.693 317.111 381.548 318.629 382.412 320.124C405.161 359.821 451.647 375.927 471.525 409.4C477.198 418.953 481.077 429.629 481.736 442.153C484.058 486.216 453.237 522.317 402.963 534C350.149 546.274 274.701 531.383 189.258 475.042Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M191.354 464.341C131.122 425.207 112.25 383.831 113.259 348.47C114.17 316.543 131.439 290.142 144.19 276.052C182.053 234.216 265.934 200.726 316.687 234.11C360.006 262.604 345.641 278.79 368.247 320.709C368.983 322.073 369.784 323.396 370.582 324.707C391.424 359.261 428.052 377.445 442.618 407.999C446.861 416.9 449.626 426.509 449.805 437.343C450.483 478.217 422.993 508.918 379.32 518.125C332.654 527.963 266.295 513.032 191.354 464.341Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M193.45 453.639C144.718 422.633 127.881 389.299 127.734 360.139C127.607 334.921 140.125 313.415 150.9 300.54C181.388 264.109 248.82 236.76 298.521 257.86C339.415 275.222 336.319 292.029 356.582 325.873C357.276 327.033 358.021 328.162 358.753 329.29C377.686 358.701 404.381 378.996 413.711 406.597C416.503 414.855 418.14 423.388 417.874 432.532C416.78 470.222 392.744 495.498 355.676 502.251C315.153 509.633 257.914 494.655 193.45 453.639Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M195.548 442.938C158.348 420.019 143.579 394.768 142.209 371.807C141.105 353.301 148.851 336.711 157.61 325.028C180.827 294.063 233.55 270.647 280.355 281.611C317.055 290.207 327.113 305.21 344.917 331.038C345.574 331.992 346.258 332.929 346.924 333.873C363.949 358.14 380.593 380.561 384.805 405.195C386.108 412.815 386.61 420.264 385.945 427.721C382.866 462.21 362.488 482.046 332.034 486.376C297.645 491.264 249.576 476.224 195.548 442.938Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
            <path d="M262.189 305.361C296.177 305.582 319.977 319.333 335.094 338.456C350.211 357.58 356.644 382.077 355.898 403.793C354.366 448.351 336.648 468.174 308.391 470.501C280.133 472.829 241.335 457.661 197.644 432.236C153.953 406.812 148.204 374.951 164.32 349.517C180.436 324.083 218.418 305.076 262.189 305.361Z" stroke="#F57A7A" stroke-opacity="0.5" stroke-width="2.10293" stroke-miterlimit="10"/>
          </svg>
        </div>

        <div class="shape circle-wrapper">
          <div class="circle-inner-wrapper">
            <div class="circle" />
          </div>
          <div class="model">
            <img :src="coverImg" :alt="t('BAN_CAN_TU_VAN?')" />
          </div>
        </div>

        <div class="shape bell-wrapper">
          <div class="bell">
            <svg width="43" height="47" viewBox="0 0 43 47" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.155 19.7705C28.1617 6.87164 25.0441 1.97181 15.2523 2.17685C11.7641 2.24782 12.6 -0.351929 9.93976 0.63382C7.28481 1.61957 9.56386 3.06271 6.84582 5.29444C-0.782558 11.5585 -0.0807054 17.3521 3.45748 31.1605C4.94793 36.9777 -0.135907 37.2616 1.87502 42.8975C3.34444 47.0087 14.1745 48.7305 25.5987 44.4931C37.0255 40.253 44.2254 31.8387 42.756 27.7275C40.7451 22.089 36.6812 25.2013 34.155 19.7705ZM24.3606 41.018C14.1561 44.8033 5.7707 42.5794 5.41583 41.5884C4.80598 39.8798 8.70955 34.1861 20.3756 29.8567C32.0416 25.5273 38.5528 27.1334 39.2336 29.0392C39.6358 30.1669 34.5677 37.2301 24.3606 41.018ZM21.08 31.8334C15.7465 33.8128 12.0401 36.0761 9.63746 38.1133C11.3277 39.6484 14.4926 40.0217 17.6444 38.852C21.6583 37.3668 24.1267 33.9469 23.1514 31.2183L23.1094 31.1237C22.4496 31.3392 21.774 31.5732 21.08 31.8334Z" fill="#E11F27"/>
            </svg>
          </div>
          <div class="text">
            <strong>{{ t('BAN_CAN_TU_VAN?') }}</strong>
            <br />
            <small>{{ t('HAY_LIEN_HE_VOI_CHUNG_TOI') }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 mobile-placeholder">
        </div>
        <div class="col-12 col-lg-6">
          <h3>{{ t('BAN_CAN_TU_VAN?') }}</h3>
          <h1 v-show="$i18n.locale === 'vi'">Bạn có bất kỳ thắc mắc gì hãy liên hệ với Camdonhanh để được giải đáp ngay nhé!</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import coverImg from '@/assets/imgs/contacts-model-min.png';

export default {
  name: 'AboutUsCover',
  components: {
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('ABOUT_US');

    return {
      t,
      coverImg
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#contacts-cover {
  width: 100%;
  position: relative;
  padding: 120px 0;
  margin: 120px 0;

  @media (max-width: 767px) {
    padding: 30px 0;
    margin-bottom: 30px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $geranium;

    .shapes-wrapper {
      position: relative;
      height: 100%;
    }

    .shape {
      position: absolute;

      &.lines {
        width: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        svg {
          width: 100%;
        }

        @media (max-width: 767px) {
          width: 100%;
          top: 120px;
        }
      }

      &.circle-wrapper {
        width: 50%;
        height: 100%;
        position: relative;

        @media (max-width: 767px) {
          width: 100%;
        }

        .circle-inner-wrapper {
          width: 70%;
          height: 100%;
          position: relative;
          left: 50%;
          transform: translateX(-50%);

          .circle {
            width: 100%;
            padding-bottom: 100%;
            background-color: $snow;
            border-radius: 50%;
            opacity: .2;
            position: relative;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }

          @media (max-width: 767px) {
            width: 90%;

            .circle {
              top: 0;
              transform: none;
            }
          }
        }

        .model {
          position: absolute;
          top: calc(50% + 10px);
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 80%;

          img {
            width: 100%;
          }

          @media (max-width: 767px) {
            top: 120px;
            width: 90%;
          }
        }
      }

      &.bell-wrapper {
        background: rgba(255, 253, 253, 0.6);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 16px;
        padding: 15px 30px;
        padding-top: 12px;
        z-index: 3;

        @media (max-width: 767px) {
          left: 50%;
          top: 120px;
          transform: translateX(-50%);
          width: 80%;
        }

        .bell {
          width: 40px;
          display: inline-block;
          margin-right: 15px;
          vertical-align: middle;
          padding-top: 3px;

          svg {
            width: 100%;
          }
        }

        .text {
          display: inline-block;
          vertical-align: middle;
          color: $raisin;

          small {
            color: $independence;
          }
        }
      }
    }
  }

  .container {
    text-align: left;
    position: relative;
    z-index: 1;
    color: $white;
  }

  h1 {
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 24px;
    padding: 15px 0;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 16px;
      padding: 0;
    }
  }

  .mobile-placeholder {
    @media (max-width: 767px) {
      padding-bottom: 90%;
    }
  }
}
</style>
