<template>
  <div id="about-us-content">
    <div class="container limited">
      <div class="row">
        <div class="col-12 col-lg-7">
          <div class="wrapper">
            <h2>{{ t('CAMDONHANH_BAT_DAU_NHU_THE_NAO?') }}</h2>
            <div v-show="($i18n.locale === 'vi')">
              <p>Camdonhanh xuất phát từ ý tưởng thay đổi định kiến về dịch vụ cầm đồ, khiến dịch vụ này trở nên an toàn và tiện lợi hơn cho mọi khách hàng. Khách hàng không còn phải đến các tiệm cầm đồ với tâm lý e ngại về lãi suất cao và tài sản không đảm bảo, thay vào đó, camdonhanh hứa hẹn mang đến sự phục vụ tốt nhất với mô hình hỗ trợ tài chính hợp pháp.</p>
              <p>Camdonhanh áp dụng những nền tảng công nghệ hiện đại để chuyên nghiệp hóa dịch vụ cầm đồ truyền thống. Mọi giao dịch và thông tin của khách hàng đều được bảo mật tuyệt đối. Chúng tôi luôn nỗ lực cải thiện để phục vụ khách hàng tốt nhất và hướng đến mô hình khởi nghiệp về tài chính - công nghệ hàng đầu.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <img :src="bunnyMoney" :alt="t('CAMDONHANH_BAT_DAU_NHU_THE_NAO?')" />
        </div>
      </div>

      <div class="row mission">
        <div class="col-12 col-lg-7 imgs">
          <div class="img-holder">
            <img :src="aboutUsImg2" :alt="t('SU_MENH_VOI_DICH_VU_CAM_DO')" />
          </div>
          <div class="img-holder">
            <img :src="aboutUsImg1" :alt="t('SU_MENH_VOI_DICH_VU_CAM_DO')" />
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div class="wrapper">
            <h2>{{ t('TAM_NHIN') }}</h2>
            <div v-show="($i18n.locale === 'vi')">
              <p>Được thành lập bởi đội ngũ  có kinh nghiệm trong lĩnh vực tư vấn và phát triển các mô hình công ty tài chính tập trung vào các khách hàng ở khu vực Đông Nam Á.</p>
              <p>Camdonhanh hướng đến việc đặt trải nghiệm của khách hàng làm trọng tâm. Thay đổi cầm đồ trở thành dịch vụ tài chính tiêu chuẩn, với chất lượng dịch vụ cao và sản phẩm đa dạng, phù hợp với nhu cầu của mọi đối tượng khách hàng.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="img-holder">
            <img :src="aboutUsImg3" :alt="t('SU_MENH_VOI_DICH_VU_CAM_DO')" />
          </div>
        </div>
      </div>
    </div>

    <div class="news-articles">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col col-12 col-lg-8">
            <h2>{{ t('BAO_CHI_NOI_VE_CAMDONHANH') }}</h2>
            <p>Camdonhanh đã xây dựng được mô hình cầm đồ hiện đại, áp dụng các công nghệ để đem đến dịch vụ phù hợp với từng phân khúc khách hàng cụ thể.</p>
          </div>
        </div>
      </div>

      <articles-about-us />
    </div>

    <div class="johngalt">
      <div class="bg">
        <div class="half">
          <div class="img-holder">
            <img :src="johngaltLogo" alt="John Galt Ventures" />
          </div>
        </div>
        <div class="half"></div>
      </div>
      <div class="content">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col col-12 col-lg-6">
            </div>
            <div class="col col-12 col-lg-6">
              <div class="text">
                <h3>{{ t('SANG_LAP_BOI') }}</h3>
                <img :src="johngaltLogoText" alt="John Galt Ventures" />
                <div v-show="($i18n.locale === 'vi')">
                  <p>John Galt Venture là quỹ đầu tư mạo hiểm với nhiều năm kinh nghiệm và chuyên môn trong lĩnh vực nghiên cứu và phát triển các mô hình công ty tài chính tập trung vào các khách hàng ở khu vực Đông Nam Á. Với định hướng áp dụng công nghệ để xây dựng các giải pháp tài chính tinh gọn, tối ưu và cá nhân hóa cho mọi khách hàng.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="core-values">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col col-12 col-lg-8">
            <h2>{{ t('GIA_TRI_COT_LOI') }}</h2>
            <p v-show="($i18n.locale === 'vi')">Camdonhanh luôn hướng đến trải nghiệm tốt nhất cho khách hàng với trọng tâm là chất lượng dịch vụ, sự chuyên nghiệp, tiện lợi và tính minh bạch.</p>
          </div>
        </div>

        <div class="core-value-wrapper">
          <div class="row">
            <div class="col col-12 col-lg-6 core-value-item" v-for="item in coreValues" :key="item.title">
              <div class="icon">
                <div v-html="item.icon" />
              </div>
              <div class="text">
                <h3>{{ t(item.title) }}</h3>
                <p>{{ item.text[$i18n.locale] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hiring" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col col-12 col-lg-6">
            <h2>{{ t('THAM_GIA_DOI_NGU_CUA_CHUNG_TOI') }}</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis semper ac dictumst bibendum nisi blandit egestas in auctor. Dictumst et cursus malesuada leo. Ultrices est accumsan, in convallis </p>
          </div>
          <div class="col col-12 col-lg-6 fist-wrapper">
            <div class="fist">
              <img :src="powerFist" :alt="t('DANG_TUYEN_DUNG')"  />
              <h2>{{ t('DANG_TUYEN_DUNG') }}</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-12 col-lg-6 hiring-position-item" v-for="item in hiringPositions" :key="item.position">
            <div class="img-holder">
              <img :src="item.image" :alt="item.position" />
            </div>
            <div class="text">
              <h3>{{ item.position }}</h3>
              <div class="apply">{{ t('UNG_TUYEN') }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-lg-6 offset-lg-6 hiring-position-item">
            <h3>{{ t('DE_LAI_EMAIL_CUA_BAN') }}</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis semper ac dictumst bibendum nisi blandit egestas in auctor. Dictumst et cursus malesuada leo. Ultrices est accumsan, in convallis </p>
            <div class="send-email-input">
              <text-input v-model:value="email" :placeholder="t('NHAP_EMAIL_CUA_BAN')" type="email">
                <template #icon>
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                    <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="white"/>
                  </svg>
                </template>
              </text-input>
              <primary-button :text="t('DANG_KY')" inverse />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import ArticlesAboutUs from '@/views/Home/ArticlesAboutUs';

import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';

import bunnyMoney from '@/assets/imgs/bunny-money-min.png';
import aboutUsImg1 from '@/assets/imgs/about-us-1-min.png';
import aboutUsImg2 from '@/assets/imgs/about-us-2-min.png';
import aboutUsImg3 from '@/assets/imgs/about-us-3-min.png';

import johngaltLogo from '@/assets/imgs/johngalt-logo-min.png';
import johngaltLogoText from '@/assets/imgs/johngalt-logo-text-min.png';
import powerFist from '@/assets/imgs/power-fist-min.png';

import developer from '@/assets/imgs/hiring-positions/developer.png';
import marketer from '@/assets/imgs/hiring-positions/marketer.png';
import designer from '@/assets/imgs/hiring-positions/designer.png';
import specialist from '@/assets/imgs/hiring-positions/specialist.png';
import businessDeveloper from '@/assets/imgs/hiring-positions/business-developer.png';
import customerService from '@/assets/imgs/hiring-positions/customer-service.png';

const coreValues = [
  {
    icon: '<svg width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6668 18.1742L5.00016 10.5076L12.6668 2.8409L10.3335 0.507568L0.333496 10.5076L10.3335 20.5076L12.6668 18.1742ZM21.3335 18.1742L29.0002 10.5076L21.3335 2.8409L23.6668 0.507568L33.6668 10.5076L23.6668 20.5076L21.3335 18.1742Z" fill="#2D2DFC"/></svg>',
    title: 'DICH_VU_TIEU_CHUAN',
    text: {
      vi: 'Camdonhanh luôn đặt trải nghiệm của khách hàng lên hàng đầu với ưu tiên tuyệt đối về chất lượng dịch vụ và sự thân thiện.'
    }
  },
  {
    icon: '<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.907227 0.507568V13.8409H14.2406V0.507568H0.907227ZM10.9072 10.5076H4.24056V3.8409H10.9072V10.5076ZM0.907227 17.1742V30.5076H14.2406V17.1742H0.907227ZM10.9072 27.1742H4.24056V20.5076H10.9072V27.1742ZM17.5739 0.507568V13.8409H30.9072V0.507568H17.5739ZM27.5739 10.5076H20.9072V3.8409H27.5739V10.5076ZM17.5739 17.1742V30.5076H30.9072V17.1742H17.5739ZM27.5739 27.1742H20.9072V20.5076H27.5739V27.1742Z" fill="#EA1C24"/></svg>',
    title: 'CHUYEN_NGHIEP',
    text: {
      vi: 'Đội ngũ nhân viên chuyên biệt và quy trình làm việc chuyên nghiệp của Camdonhanh sẽ luôn đảm bảo bạn được phục vụ với tiêu chuẩn cao nhất về bảo mật cá nhân và an toàn tài sản.'
    }
  },
  {
    icon: '<svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0.174225L0 6.84089V16.8409C0 26.0909 6.4 34.7409 15 36.8409C23.6 34.7409 30 26.0909 30 16.8409V6.84089L15 0.174225ZM15 18.4909H26.6667C25.7833 25.3576 21.2 31.4742 15 33.3909V18.5076H3.33333V9.00756L15 3.82422V18.4909Z" fill="#139BFE"/></svg>',
    title: 'MINH_BACH',
    text: {
      vi: 'Quyền lợi của khách hàng sẽ luôn được đặt lên hàng đầu với sự minh bạch tuyệt đối về quy trình làm việc và lãi suất cầm cố.'
    }
  },
  {
    icon: '<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7574 14.1576C17.7574 11.4076 15.5074 9.15758 12.7574 9.15758C10.0074 9.15758 7.75739 11.4076 7.75739 14.1576C7.75739 16.9076 10.0074 19.1576 12.7574 19.1576C15.5074 19.1576 17.7574 16.9076 17.7574 14.1576ZM12.7574 15.8242C11.8407 15.8242 11.0907 15.0742 11.0907 14.1576C11.0907 13.2409 11.8407 12.4909 12.7574 12.4909C13.6741 12.4909 14.4241 13.2409 14.4241 14.1576C14.4241 15.0742 13.6741 15.8242 12.7574 15.8242ZM23.5907 19.1576C25.4407 19.1576 26.9241 17.6742 26.9241 15.8242C26.9241 13.9742 25.4407 12.4909 23.5907 12.4909C21.7407 12.4909 20.2407 13.9742 20.2574 15.8242C20.2574 17.6742 21.7407 19.1576 23.5907 19.1576ZM16.9074 0.840912C7.70739 0.840912 0.240723 8.30758 0.240723 17.5076C0.240723 26.7076 7.70739 34.1742 16.9074 34.1742C26.1074 34.1742 33.5741 26.7076 33.5741 17.5076C33.5741 8.30758 26.1074 0.840912 16.9074 0.840912ZM6.65739 26.0242C7.79072 25.1242 10.4407 24.1742 12.7574 24.1742C12.8741 24.1742 13.0074 24.1909 13.1407 24.1909C13.5407 23.1242 14.2574 22.0409 15.3074 21.0909C14.3741 20.9242 13.4907 20.8242 12.7574 20.8242C10.5907 20.8242 7.10739 21.5742 4.87406 23.2076C4.04072 21.4742 3.57406 19.5409 3.57406 17.4909C3.57406 10.1409 9.55739 4.15758 16.9074 4.15758C24.2574 4.15758 30.2407 10.1409 30.2407 17.4909C30.2407 19.4909 29.7907 21.3909 28.9907 23.1076C27.3241 22.1242 25.0574 21.6576 23.5907 21.6576C21.0574 21.6576 16.0907 23.0076 16.0907 26.1576V30.7909C12.3074 30.5742 8.94072 28.7742 6.65739 26.0242Z" fill="#F256C6"/></svg>',
    title: 'TIEN_LOI',
    text: {
      vi: 'Với quy trình làm việc tối đơn giản, bạn sẽ chỉ cần vài thao tác trên máy tính hoặc điện thoại là có thể nhận được tiền chỉ trong vòng 15 phút.'
    }
  }
];

const hiringPositions = [
  {
    image: developer,
    position: 'Developer',
  },
  {
    image: marketer,
    position: 'Marketer',
  },
  {
    image: designer,
    position: 'Designer',
  },
  {
    image: specialist,
    position: 'Specialist',
  },
  {
    image: businessDeveloper,
    position: 'Business Developer',
  },
  {
    image: customerService,
    position: 'Customer Service',
  }
];

export default {
  name: 'AboutUsContent',
  components: {
    ArticlesAboutUs,
    PrimaryButton,
    TextInput
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const email = ref('');

    return {
      t,
      bunnyMoney,
      aboutUsImg1,
      aboutUsImg2,
      aboutUsImg3,
      johngaltLogo,
      johngaltLogoText,
      coreValues,
      powerFist,
      hiringPositions,
      email
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#about-us-content {
  width: 100%;

  .container.limited {
    max-width: 960px;
  }

  .row {
    margin-bottom: 30px;

    &.justify-content-center {
      text-align: center;
    }
  }

  h2 {
    font-weight: 700;
    color: $geranium;
    font-size: 40px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      font-size: 28px;
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 18px;
    line-height: 30px;
  }

  img {
    max-width: 100%;
  }

  .imgs {
    display: flex;
    justify-content: space-between;

    .img-holder {
      width: calc(50% - 12px);
      align-self: flex-end;
      border-radius: 24px;
      overflow: hidden;
      height: auto;

      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }

  .wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .mission {
    margin-bottom: 25px;
  }

  .news-articles {
    text-align: center;
    padding: 90px 0;
  }

  .johngalt {
    position: relative;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .half {
        width: 50%;
        height: 100%;
        display: inline-block;

        @media (max-width: 767px) {
          width: 100%;
          display: block;
          height: auto;
        }

        &:first-child {
          background-color: $black;

          @media (max-width: 767px) {
            height: 200px;
          }

          .img-holder {
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
            }
          }
        }

        &:last-child {
          background-color: $raisin;
        }
      }
    }

    .content {
      position: relative;
      z-index: 1;
      color: $white;

      .col {
        height: 100%;
        padding: 30px;

        @media (max-width: 767px) {
          &:first-child {
            height: 200px;
          }

          &:last-child {
            background-color: $raisin;
          }
        }
      }

      .text {
        img {
          width: 200px;
          margin: 15px 0;
        }
      }
    }
  }

  .core-values {
    padding: 90px 0;

    .core-value-wrapper {
      background-color: $white;
      border-radius: 40px;
      padding: 60px 90px;
      box-shadow: 0px 4px 114px rgba(0, 0, 0, 0.09);

      @media (max-width: 767px) {
        padding: 0 15px;
        padding-top: 30px;
      }

      .core-value-item {
        margin-bottom: 30px;

        @media (min-width: 992px) {
          &:nth-child(1) {
            border-right: 1px $gainsboro solid;
            border-bottom: 1px $gainsboro solid;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 15%;
              height: 5px;
              background-color: $white;
            }
          }

          &:nth-child(2) {
            border-bottom: 1px $gainsboro solid;
            position: relative;
            padding-left: 20px;

            &::after {
              content: "";
              position: absolute;
              bottom: -2px;
              right: 0;
              width: 15%;
              height: 5px;
              background-color: $white;
            }
          }

          &:nth-child(3) {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: -30px;
              right: 0;
              width: 0;
              height: calc(100% + 30px);
              border-right: 1px $gainsboro solid;
            }
          }

          &:nth-child(4) {
            position: relative;
            padding-left: 20px;
          }
        }

        .icon {
          width: 88px;
          height: 88px;
          border-radius: 20px;
          background-color: $palePurple;
          position: relative;
          margin-right: 15px;
          display: inline-block;
          vertical-align: top;

          svg {
            width: 36px;
            height: 36px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }

        .text {
          display: inline-block;
          width: calc(100% - 88px - 20px);
          vertical-align: top;
          padding-top: 2px;

          h3 {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 7px;
            color: $raisin;
          }

          p {
            color: $independence;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .hiring {
    background-color: $raisin;
    color: $white;
    padding: 70px 0;
    margin-bottom: 5px;

    h2 {
      color: $white;
    }

    .fist-wrapper {
      position: relative;

      .fist {
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;

        img {
          width: 120px;
        }

        h2 {
          color: $salmonPink;
        }
      }
    }

    .hiring-position-item {
      margin-bottom: 30px;

      h3 {
        font-weight: 700;
        font-size: 40px;
        color: $white;
      }

      .img-holder {
        width: 100%;
        height: 0;
        padding-bottom: 65.20%;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 12px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        display: flex;
        justify-content: space-between;

        .apply {
          color: $blueCrayola;
          text-decoration: underline;
          font-weight: 700;
          transition: all .2s ease-in-out;
          cursor: pointer;
          align-self: flex-end;
          padding-bottom: 12px;
          padding-right: 15px;

          &:hover {
            color: $salmonPink;
          }
        }
      }
    }

    .send-email-input {
      position: relative;

      .text-input {
        width: 100%;

        input {
          background-color: rgba($color: $lava, $alpha: .1);
          color: $white;
          height: 72px;
        }

        .icon {
          top: 28px;
        }
      }

      .primary-button {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 47px;

        &::before {
          border-color: $lava;
          background-color: $lava;
          box-shadow: 0px 6px 20px $lava;
        }

        &::after {
          border-color: $lava;
        }
      }
    }
  }
}
</style>
