<template>
  <div id="loader" v-if="show" ref="elemRef">
    <div class="spinner">
      <div class="rect1" />
      <div class="rect2" />
      <div class="rect3" />
      <div class="rect4" />
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { TweenMax } from 'gsap';

export default {
  name: 'Loader',
  setup() {
    const store = useStore();
    const isActive = computed(() => store.state.loader.isActive);
    const show = ref(false);
    const elemRef = ref(null);

    watch(isActive, (val) => {
      if (elemRef.value) {
        if (val) {
          show.value = val;
        }

        TweenMax.to(elemRef.value, .5, {
          opacity: +val,
          onComplete: () => {
            if (!val) {
              show.value = val;
            }
          }
        });
      } else {
        show.value = val;
      }
    });

    return {
      show,
      elemRef
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';

.spinner {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  display: none;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 25px);

  div {
    background-color: $grey;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1.0);
  }
}

@keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

#loader {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    background-color: $black;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &::after {
    content: "";
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    z-index: 2;
    border-radius: 4px;
  }

  .spinner {
    display: block;
    z-index: 3;

    div {
      background-color: $geranium;
      margin-left: 1px;
      margin-right: 1px;
    }
  }
}
</style>
