import { FLASH_TIMEOUT } from '@/consts';

let activeTimeout;

const startActiveTimeout = (state) => {
  if (state.hovering) return;

  state.isActive = false;
  state.acts = [];
};

const mutations = {
  setMessage(state, msg) {
    if (activeTimeout) clearTimeout(activeTimeout);
    state.message = msg;
  },

  setActs(state, acts) {
    state.acts = acts;
  },

  setType(state, type) {
    state.type = type;
  },

  setActiveStatus(state, val) {
    state.isActive = val;

    if (val) {
      if (activeTimeout) clearTimeout(activeTimeout);

      activeTimeout = setTimeout(() => {
        startActiveTimeout(state);
      }, FLASH_TIMEOUT);
    }
  },

  setHovering(state, val) {
    state.hovering = val;

    if (!val) {
      activeTimeout = setTimeout(() => {
        startActiveTimeout(state);
      }, FLASH_TIMEOUT);
    } else {
      if (activeTimeout) clearTimeout(activeTimeout);
    }
  }
};

export default mutations;
