<template>
  <div
    class="select-input input"
    :class="{
      disabled
    }"
  >
    <label v-if="!!label">{{ label }} <span v-if="required && !!label">*</span></label>

    <div class="display" @click="toggle">
      <div class="value">{{ getDisplayText(wrappedValue) || placeholder }}</div>
      <font-awesome-icon class="drop" :icon="faCaretDown" />
    </div>

    <small class="red" v-if="!!validateMessage">{{ validateMessage }}</small>

    <transition name="fade">
      <div v-show="active" class="select-box-wrapper" ref="wrapper">
        <ul>
          <li
            v-for="item in items"
            :key="item.value"
            @click="() => select(item.value)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, watch, onUnmounted, computed } from 'vue';
import $ from 'jquery';
import { useI18n } from 'vue-i18n';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SelectInput',
  components: {
    FontAwesomeIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String],
      default: null
    },
    placeholder: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => ''
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const active = ref(false);
    const validateMessage = ref('');
    const isValid = ref(false);
    const wrapper = ref(null);

    const wrappedValue = computed({
      get() { return props.value; },
      set(val) {
        emit('input', val);
      }
    });

    const toggle = (val) => {
      if ((val === false) || (val === true)) {
        active.value = val;
      } else {
        active.value = !active.value;
      }
    };

    const watchWrapper = (e) => {
      const elem = wrapper.value;
      if (!elem) return;

      let { pageX, pageY } = e;
      let { top, left } = $(elem).offset();
      let width = $(elem).width();
      let height = $(elem).height();

      if ((pageX < left) || (pageX > left + width) || (pageY < top) || (pageY > top + height)) {
        ({ pageX, pageY } = e);
        ({ top, left } = $(elem).offset());
        width = $(elem).outerWidth();
        height = $(elem).outerHeight();

        if ((pageX < left) || (pageX > left + width) || (pageY < top) || (pageY > top + height)) {
          toggle(false);
        }
      }
    };

    const select = (val) => {
      emit('update:value', val);
      toggle(false);
    };

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const clearValidateMessage = () => {
      validateMessage.value = '';
    };

    const validateInput = () => {
      const val = wrappedValue.value;

      if ((props.required) && !val) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      const msg = props.validate(val);

      if (msg) {
        alert(t(msg));
        return false;
      }

      alert('');
      return true;
    };

    const getDisplayText = (val) => {
      const item = props.items.find((i) => (i.value === val));
      return item?.text || val;
    };

    watch(active, (next) => {
      if (next) {
        setTimeout(() => {
          $(document.body).on('click', watchWrapper);
        }, 0);
      } else {
        $(document.body).off('click', watchWrapper);
      }
    });

    watch(wrappedValue, () => {
      isValid.value = validateInput();
      if (wrappedValue.value === '' && !props.required) {
        alert('');
      }
    });

    onUnmounted(() => {
      $(document.body).off('click', watchWrapper);
    });

    return {
      active,
      toggle,
      faCaretDown,
      wrappedValue,
      select,
      isValid,
      validateMessage,
      validateInput,
      getDisplayText,
      clearValidateMessage,
      wrapper
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors';

.select-input {
  position: relative;
  display: inline-block;
  text-align: left;

  label {
    font-weight: bold;
    padding-left: 2px;
    color: $raisin;
    font-size: 18px;
    margin-bottom: 10px;
    margin-right: 7px;

    span {
      color: $raisin;
    }
  }

  .display {
    height: 56px;
    border: 0;
    background-color: $white;
    border-radius: 16px;
    color: $raisin;
    cursor: pointer;
    padding: 15px;
    padding-top: 12px;
    position: relative;
    text-align: left;

    .value {
      display: inline-block;
      padding-top: 3px;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }

  small {
    &.red {
      color: $geranium;
      font-size: 12px;
    }
  }

  .select-box-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 3px 6px #00000029;
    background-color: $white;
    border-radius: 4px;
    z-index: 999;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    min-width: 80px;

    &.fade-enter-active, &.fade-leave-active {
      transition: opacity .2s ease-in-out;
    }

    &.fade-enter, &.fade-leave-to {
      opacity: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: left;
      border-radius: 4px;

      li {
        padding: 5px 15px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        color: $raisin;

        &:hover {
          background-color: $candyPink;
          color: $white;
        }
      }
    }
  }
}
</style>
