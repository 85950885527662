<template>
  <div
    class="radio-input input"
    :class="{
      disabled,
      active,
    }"

    @click="activate"
  >
    <div class="radio">
      <div class="outer"></div>
      <div class="inner"></div>
    </div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';

export default {
  name: 'RadioInput',
  components: {},
  props: {
    text: String,
    value: {
      type: [Number, String],
      default: ''
    },
    refValue: {
      type: [Number, String],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  setup(props, { emit }) {
    const isValid = ref(false);
    const cacheValue = ref(props.refValue);

    const active = computed(() => props.value === cacheValue.value);

    const wrappedValue = computed({
      get() { return cacheValue.value; },
      set(value) {
        cacheValue.value = value;
        emit('update:ref-value', value);
      }
    });

    const activate = () => {
      wrappedValue.value = props.value;
    };

    watch(props, (newProps) => {
      cacheValue.value = newProps.refValue;
    });

    return {
      isValid,
      wrappedValue,
      active,
      activate
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';
@import '~@/assets/scss/dimens';

.radio-input {
  display: inline-block;
  position: relative;
  width: $quantityInputWidth;
  cursor: pointer;

  .radio {
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;

    .outer {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: $white;
      border: 1px solid $raisin;
      border-radius: 50%;
    }

    .inner {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $redSalsa;
      display: none;
    }
  }

  .text {
    display: inline-block;
    vertical-align: middle;
  }

  &.disabled {
    pointer-events: none;
    color: $grey;
  }

  &.active {
    .radio {
      .outer {
        border-color: $redSalsa;
      }

      .inner {
        display: block;
      }
    }
  }
}
</style>
