<template>
  <div id="facebook-pixel">
  </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  setup() {
    const load = () => {
      if (process.env.VUE_APP_ENV === 'dev') return;
      if (!process.browser) return;

      /* eslint-disable */
      setTimeout(() => {
        (function() {
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');

          window.fbq('init', '1785024651775374');
        })();
      }, 0);
    };

    onMounted(() => {
      load();
    });

    return {};
  }
}
</script>
