<template>
  <div id="stats">
    <div class="title">
      <div class="bg">
        <img :src="statsBg" :alt="t('THONG_KE')" />
      </div>

      <div class="container">
        <h1>{{ t('NHUNG_THONG_KE_DANG_NHO') }}</h1>
        <p>{{ t('HANH_TRINH_THAY_DOI_DINH_KIEN_VE_CAM_DO') }}</p>
      </div>
    </div>

    <div class="stats">
      <div class="container">
        <div class="row">
          <div class="col col-12 col-md-4 stat-item-wrapper" v-for="item in numbers" :key="item.text">
            <div class="stat-item">
              <div class="number">{{ item.number }}</div>
              <div class="text">{{ t(item.text) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import statsBg from '@/assets/imgs/stats-bg.png';

const numbers = [
  {
    text: 'HOP_DONG',
    number: '100.000+'
  },
  {
    text: 'KHACH_HANG_HAI_LONG',
    number: '10.000+'
  },
  {
    text: 'NAM_HOAT_DONG',
    number: 6
  }
];

export default {
  name: 'AboutUsCover',
  components: {
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('ABOUT_US');

    return {
      t,
      statsBg,
      numbers
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#stats {
  width: 100%;
  margin-top: 90px;

  .title {
    color: $white;
    position: relative;
    padding: 40px 0;
    width: 100%;
    text-align: center;
    background-color: $raisin;
    overflow: hidden;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h1 {
      font-weight: 700;
    }
  }

  .stats {
    padding: 50px 0;

    .stat-item-wrapper {
      text-align: center;
      border-right: 1px solid $grey;

      .number {
        font-weight: 700;
        font-size: 48px;
        color: $geranium;
      }

      .text {
        color: $grey;
        font-size: 20px;
      }

      &:last-child {
        border: 0;
      }

      @media (max-width: 767px) {
        border: 0;
        position: relative;
        margin-bottom: 15px;

        &::after {
          content: "";
          position: absolute;
          bottom: -20px;
          left: 50%;
          width: 30%;
          transform: translateX(-50%);
          height: .5px;
          background-color: $grey;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
