<template>
  <div class="logo" title="Camdonhanh">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528.24 421.87"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M485,121.15c-12.3-12.42-29.78-18.58-48.1-19.22a4,4,0,0,1-3-1.53C398.38,55.39,313.3,12.93,168.36,0a6.91,6.91,0,0,0-6.15,11C213,79.33,321,113.93,369.93,126.62l0,0h0S465.76,150.25,483.8,168l.42.41,0,0c.18.19.34.38.5.56s.09.09.13.15a8.59,8.59,0,0,1,1.23,1.83c2.19,3.95,2.26,9.24-1.38,14.35a4,4,0,0,1-1,1c-9.95,7-20.65,0-20.65-9.41a12.56,12.56,0,0,1,.22-2.31,4,4,0,0,0-2.23-4.35c-15.22-7-51.75-20.77-103.87-21.2a4,4,0,0,0-3.92,3h0a4,4,0,0,1-5.38,2.69c-47.42-19-182.9-94.16-275.25-60.54A101.31,101.31,0,0,0,49.94,105.7c-4.61,2.85-32.85,21.1-48.07,49.89a15.86,15.86,0,0,0,10.76,23l.19,0A4,4,0,0,1,16,182.19C19.38,222,44.73,261.5,74.49,273.4c40.18,16.07,77.19,11.29,88.56,9.42,1.64-.27,10.32-1.65,15,5,6.74,9.54,43.17,66.95,62.62,100.11,17.76,30.29,32.82,28.44,67.85,30.78l47.38,3.17a4,4,0,0,0,4.31-4.27c-.26-4.49-1.93-11.26-8.94-15.42-4-2.39-14.39-5.14-26-7.69a79.11,79.11,0,0,1-59-54.82C251.91,291,230.21,219.54,222.4,204.15c-7.05-13.9-23.38-27.46-39.62-32.63-4.64-1.48-3.11-8.42,1.73-7.86,15.89,1.86,32.93,10.37,42.79,21.58,14.9,16.94,33.88,75.53,43.12,106.21a20.21,20.21,0,0,0,24.87,13.64l.3-.09c12.93-7.39,51.65-46.22,92.27-46.22s50.5,11.33,91.58,9.45c5.56-.25,27.52-15.3,40.68-25.18a20.3,20.3,0,0,0,7.68-20.41C522.72,198.4,509.34,145.68,485,121.15Z"/><path class="cls-1" d="M134.7,321.56c-7.65-.23-29.07-4.55-27,.35s22,17.92,39.22,17.86c3.4,0,15.3-2.6,25.76-4.39a11.84,11.84,0,0,0,8.07-17.9h0a11.83,11.83,0,0,0-14.48-4.76C155.84,317,145.08,321.88,134.7,321.56Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'Logo'
};
</script>

<style lang="scss">
.logo {
  display: inline-block;

  svg {
    path, polygon {
      fill: currentColor;
    }
  }
}
</style>
