import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';

export default {
  async getTags() {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: '/v1/tags/',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const tags = processSuccessResponse(res);

      return tags;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getArticles(params) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: '/v1/articles/',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, { params });
      const tags = processSuccessResponse(res);

      return tags;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async readArticle(slug) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/articles/${slug}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const article = processSuccessResponse(res);

      return article;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },
};
