<template>
  <div id="about-us">
    <cover :item="item" />
    <item-content :item="item" />
    <assurance />
  </div>
</template>

<script>
import { onMounted, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';
import { useRoute } from 'vue-router';

import Assurance from '@/views/Home/Assurance';

import Cover from './Cover';
import ItemContent from './Content';

export default {
  name: 'EligibleItemDetail',
  components: {
    Cover,
    ItemContent,
    Assurance,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    const eligibleItems = computed(() => store.state.eligibleItem.items);

    const slug = computed(() => route.params.slug);
    const item = computed(() => eligibleItems.value.find((i) => i.slug === slug.value));

    const title = t('ELIGIBLE_ITEMS');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    onMounted(async () => {
      await store.dispatch('eligibleItem/getItems');
    });

    watch(item, (val) => {
      if (val) {
        meta.title = val.metaTitle;
        meta.description = val.metaDescription;
        meta.og = {
          title: `${val.metaTitle} | Camdonhanh`,
          description: val.metaDescription,
          image: val.thumbnail,
        };
      }
    }, {
      immediate: true
    });

    return {
      t,
      item
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#about-us {
  width: 100%;
}
</style>
