<template>
  <div id="blog-list-cover">
    <div class="bg-wrapper">
      <div class="bg">
        <svg viewBox="0 0 1440 748" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isDesktop">
          <g clip-path="url(#clip0_1432_10000)">
            <path d="M-204 676.47V-241H1644V748C1578.56 645.139 1285.32 539.323 1091.47 676.47C897.617 813.618 751.522 709.183 658.498 676.47C557.636 641.001 413.968 518.632 234.383 676.47C90.7156 802.741 -117.734 729.083 -204 676.47Z" fill="#E11F27"/>
            <g filter="url(#filter0_f_1432_10000)">
              <path d="M292 47L297.613 64.2746H315.776L301.082 74.9508L306.695 92.2254L292 81.5491L277.305 92.2254L282.918 74.9508L268.224 64.2746H286.387L292 47Z" fill="#FAB2B2"/>
            </g>
            <circle cx="-23" cy="246" r="24" fill="url(#paint0_radial_1432_10000)"/>
            <circle cx="1438" cy="93" r="24" fill="url(#paint1_radial_1432_10000)"/>
            <circle cx="2.5" cy="567.5" r="71.5" fill="url(#paint2_radial_1432_10000)"/>
          </g>
          <defs>
            <filter id="filter0_f_1432_10000" x="263.224" y="42" width="57.5527" height="55.2253" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_1432_10000"/>
            </filter>
            <radialGradient id="paint0_radial_1432_10000" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-32 236.5) rotate(45.4308) scale(47.0239)">
              <stop stop-color="#FBA6A0"/>
              <stop offset="1" stop-color="#FA2D2D"/>
            </radialGradient>
            <radialGradient id="paint1_radial_1432_10000" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1429 83.5) rotate(45.4308) scale(47.0239)">
              <stop stop-color="#F47A7A"/>
              <stop offset="1" stop-color="#C12C35"/>
            </radialGradient>
            <radialGradient id="paint2_radial_1432_10000" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-24.3125 539.198) rotate(45.4308) scale(140.092)">
              <stop stop-color="#F47A7A"/>
              <stop offset="1" stop-color="#C12C35"/>
            </radialGradient>
            <clipPath id="clip0_1432_10000">
              <rect width="1440" height="748" fill="white"/>
            </clipPath>
          </defs>
        </svg>

        <svg viewBox="0 0 375 560" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
          <g clip-path="url(#clip0_1432_22724)">
            <path d="M-100 461.498V-58H946.076V502C909.034 443.757 743.044 383.841 633.312 461.498C523.579 539.155 440.881 480.021 388.224 461.498C331.13 441.414 249.806 372.126 148.151 461.498C66.8263 532.996 -51.1683 491.289 -100 461.498Z" fill="#E11F27"/>
            <circle cx="-23" cy="246" r="24" fill="url(#paint0_radial_1432_22724)"/>
            <circle cx="375" cy="93" r="24" fill="url(#paint1_radial_1432_22724)"/>
            <circle cx="2.5" cy="317.5" r="71.5" fill="url(#paint2_radial_1432_22724)"/>
          </g>
          <defs>
            <radialGradient id="paint0_radial_1432_22724" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-32 236.5) rotate(45.4308) scale(47.0239)">
              <stop stop-color="#FBA6A0"/>
              <stop offset="1" stop-color="#FA2D2D"/>
            </radialGradient>
            <radialGradient id="paint1_radial_1432_22724" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(366 83.5) rotate(45.4308) scale(47.0239)">
              <stop stop-color="#F47A7A"/>
              <stop offset="1" stop-color="#C12C35"/>
            </radialGradient>
            <radialGradient id="paint2_radial_1432_22724" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-24.3125 289.198) rotate(45.4308) scale(140.092)">
              <stop stop-color="#F47A7A"/>
              <stop offset="1" stop-color="#C12C35"/>
            </radialGradient>
            <clipPath id="clip0_1432_22724">
              <rect width="375" height="560" fill="white"/>
            </clipPath>
          </defs>
        </svg>

      </div>

      <div class="bg-img">
        <img :src="coverImg" alt="Camdonhanh" />
      </div>
    </div>
    <div class="container">
      <div class="content-wrapper">
        <div class="row">
          <div class="col col-12 col-xs-12 col-md-6">
            <h1>{{ t('TIN_TUC_MOI_NHAT') }}</h1>
            <p v-if="$i18n.locale === 'vi'">Thông tin & các giải pháp tài chính cùng Camdonhanh</p>
            <div class="search-input">
              <div class="cursor">
                <img :src="cursor" :alt="t('NHAP_TU_KHOA_CAN_TIM_KIEM')" />
              </div>

              <form @submit="search">
                <text-input v-model:value="searchTerm" :placeholder="t(isDesktop ? 'NHAP_TU_KHOA_CAN_TIM_KIEM' : 'NHAP_TU_KHOA')" />
                <primary-button :text="t('TIM')" inverse @click="search" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';

import coverImg from '@/assets/imgs/blog-list-cover-min.png';
import cursor from '@/assets/imgs/cursor-min.png';

export default {
  name: 'AboutUsCover',
  components: {
    PrimaryButton,
    TextInput
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const isDesktop = computed(() => store.state.app.isDesktop);

    const filters = computed(() => store.state.blog.filters);
    const searchTerm = ref(filters.value.term);

    const search = (e) => {
      e.preventDefault();
      store.dispatch('blog/applyFilters', {
        term: searchTerm.value,
        page: 1
      });
    };

    watch(filters, (val) => {
      searchTerm.value = val.term;
    });

    return {
      t,
      coverImg,
      cursor,
      searchTerm,
      search,
      isDesktop
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#blog-list-cover {
  width: 100%;
  position: relative;
  padding: 100px 0;
  height: 748px;

  @media (max-width: 767px) {
    padding: 30px 0;
    height: 560px;
  }

  .bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 748px;
    overflow: hidden;

    @media (max-width: 767px) {
      height: 560px;
    }

    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      @media (max-width: 767px) {
        bottom: auto;
        top: 0;
      }
    }

    .bg-img {
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: right;
      }

      @media (max-width: 767px) {
        top: 90px;
      }
    }
  }

  .container {
    text-align: left;
    position: relative;
    z-index: 1;
    color: $white;
    height: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 48px;
  }

  h3 {
    font-size: 24px;
    padding: 15px 0;
  }

  p {
    color: $gainsboro;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 16px;
    }
  }

  .content-wrapper {
    position: relative;
    top: 37%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      transform: none;
      top: 0;
    }

    .search-input {
      position: relative;
      width: 100%;

      .cursor {
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%) translateX(-100%);
        width: 80px;

        img {
          width: 100%;
        }
      }

      .text-input {
        width: 100%;

        input {
          height: 72px;
        }

        .icon {
          top: 28px;
        }
      }

      .primary-button {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 47px;

        &::before {
          border-color: $lava;
          background-color: $lava;
          box-shadow: 0px 6px 20px $lava;
        }

        &::after {
          border-color: $lava;
        }
      }
    }
  }
}
</style>
