import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

import * as errors from '@/errors';

let refreshTries = 0;

export const initInterceptors = () => {
  axios.interceptors.request.use((config) => {
    // set Authorization header
    // TODO: uncomment when there is user implementation
    // const { token } = store.state.user;

    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    let { requestId } = config;
    if (!requestId) {
      requestId = uuidv4();
      config.requestId = requestId;
    }

    if (config.showLoader !== false) {
      // Loader interceptor
      store.dispatch('loader/toggle', { val: true, id: requestId });
    }

    return config;
  }, (err) => {
    store.dispatch('loader/toggle', { val: false, id: err.config.requestId });
    return Promise.reject(err);
  });

  axios.interceptors.response.use((res) => {
    // Loader interceptor
    store.dispatch('loader/toggle', { val: false, id: res.config.requestId });
    return res;
  }, async (err) => {
    if (axios.isCancel(err)) {
      return false;
    }

    const res = err.response;
    const { InvalidToken, InvalidRefreshToken } = errors;

    // Expired token interceptor
    if ((res.status === 401) && (res.data?.error?.code === InvalidToken.code)) {
      try {
        await store.dispatch('user/refreshToken');
        const { token } = store.state.user;

        if (token) {
          const config = { ...res.config };
          config.headers.Authorization = `Bearer ${token}`;
          return axios.request(config);
        }
      } catch(e) {
        // do nothing
      }
    }

    // Expired refresh token interceptor
    if ((res.status === 400) && (res.data?.error?.code === InvalidRefreshToken.code)) {
      await store.dispatch('user/logout');

      if (refreshTries < 3) {
        refreshTries += 1;

        try {
          await store.dispatch('user/guestLogin');
          const { refreshToken } = store.state.user;

          if (refreshToken) {
            const config = { ...res.config };
            config.data = JSON.stringify({
              refreshToken
            });
            return axios.request(config);
          }
        } catch(e) {
          // do nothing
        }
      }

      refreshTries = 0;
    }

    store.dispatch('loader/toggle', { val: false, id: err.config.requestId });
    return Promise.reject(err);
  });
};

export default {};
