export { default as DEFAULT_PROFILE_PICTURE } from '@/assets/imgs/default-avatar.jpg';

export const PASSWORD_MIN_LENGTH = 6;
export const FLASH_TIMEOUT = 5000;

export const FLASH_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  ACT: 'act'
};

export const LINKS = {
  FACEBOOK: 'https://www.facebook.com/xoimusic',
  INSTAGRAM: 'https://www.instagram.com/xoimusic/',
  YOUTUBE: 'https://www.youtube.com/channel/UC5MR1chgaBFq_Yl5pA-sDMw',
  SPOTIFY: 'https://open.spotify.com/artist/15HLcx4BDvQLmcu893h3C3?si=xUPoG4hKQCi2jPIlWp9mWg',
  BANDCAMP: 'https://xoimusic.bandcamp.com/'
};

export const MAX_AMOUNT = 9 * 10 ** 18;

export default {};
