<template>
  <div id="submit-order">
    <div class="container main">
      <div class="grid" />
      <div class="row main-inner">
        <div class="col-12 col-md-5 bg">
          <img :src="submitOrderBg" :alt="t('GUI_THONG_TIN')" />
          <div class="blend" />
        </div>
        <div class="col-12 col-md-7 form">
          <div class="progress-step" v-show="(step < 3)">
            <div class="step step1" :class="{ current: (step === 1), done: (step > 1) }">1</div>
            <div class="line" />
            <div class="step step2" :class="{ current: (step === 2), done: (step > 2) }">2</div>
          </div>

          <div class="form-step" v-show="step === 1">
            <h1>{{ t('GUI_YEU_CAU') }}</h1>

            <div class="space" />

            <text-input :label="t('TEN_CUA_BAN')" :placeholder="t('NHAP_TEN_CUA_BAN')" v-model:value="name" ref="nameInputRef" required />
            <text-input :label="t('DIEN_THOAI_LIEN_HE')" :placeholder="t('NHAP_SO_DIEN_THOAI_LIEN_HE')" v-model:value="phoneNumber" ref="phoneNumberInputRef" type="phone" required/>
            <select-input :label="t('LOAI_TAI_SAN')" :placeholder="t('CHON_LOAI_TAI_SAN')" :items="assetCategories" v-model:value="assetCategory" ref="assetCategoryInputRef" required />

            <div class="terms-content" :class="{ active: termsActive }" :style="{ height: termsHeight }" ref="termsRef">
              <h3>Quy định và các điều khoản hoạt động</h3>

              <p><strong>Quy định về định giá online:</strong></p>
              <p>1. Khách hàng (KH) phải cam kết chịu trách nhiệm hoàn toàn về nguồn gốc hợp pháp của tài sản cầm cố (TSCC). Trường hợp phát sinh bất kỳ tranh chấp, khiếu kiện liên quan đến TSCC thì Khách hàng có trách nhiệm giải quyết, đồng thời phải hoàn thành mọi nghĩa vụ theo hợp đồng này và bồi thường cho ICADO toàn bộ chi phí thiệt hại phát sinh.</p>
              <p>2. KH cam kết về tính chính hãng và nguyên gốc của TSCC. Trong truờng hợp phát hiện TSCC giả mạo, KH phải chịu trách nhiệm hoàn toàn trước pháp luật đồng thời bồi thường cho ICADO toàn bộ chi phí thiệt hại phát sinh.</p>
              <p>3. KH cam kết cung cấp thông tin chính xác về tình trạng của TSCC cho ICADO để nhận được định giá online chính xác nhất. ICADO sẽ không chịu trách nhiệm với bất kì trường hợp nào xảy ra sai lệch trong quá trình định giá online do thông tin sai lệch từ KH.</p>
              <p>4. Mức giá được đưa ra bởi ICADO chỉ có hiệu lực trong vòng 24 tiếng. Sau thời gian kể trên, ICADO không đảm bảo giữ nguyên mức giá đưa ban đầu.</p>

              <p><strong>Quy định về bảo mật thông tin:</strong></p>
              <p>1. Mọi thông tin cá nhân cũng như tài sản cầm cố của KH sẽ đều được bảo mật. ICADO cam kết sẽ không mua bán, trao đổi, hoặc tiết lộ thông tin của KH cho bất kì một bên thứ ba nào khác.</p>
              <p>2. Khách hàng có trách nhiệm bảo mật về quy trình định giá, cũng như mức ICADO đưa ra cho TSCC.</p>
              <p><strong>Chính sách lưu kho và bảo quản tài sản:</strong></p>
              <p>1. TSCC sẽ luôn được niêm phong bằng tem vỡ tại mọi thời điểm kể từ khi KH ký kết HĐ cầm cố với ICADO. Tem niêm phong tài sản chỉ được tháo gỡ khi có sự đồng ý từ KH.</p>
              <p>2. Trong các trường hợp có yêu cầu tháo gỡ tem niêm phong từ các cơ quan có thẩm quyền ICADO sẽ thông báo với KH trước khi tiến hành tháo gỡ tem niêm phong.</p>
            </div>

            <check-input v-model:value="acceptTermsAndConditions" ref="acceptTermsAndConditionsInputRef" required>
              <small v-show="$i18n.locale === 'vi'" class="terms-text"><span>Tôi đồng ý với</span> <span class="terms-link" @click="toggleTermsActive">điều kiện & điều khoản</span> <span>của Camdonhanh</span></small>
            </check-input>

            <primary-button :text="t('GUI_YEU_CAU')" inverse @click="submit()" />
          </div>

          <div class="form-step" v-show="step === 2">
            <h1>{{ t('GUI_THONG_TIN') }}</h1>
            <p>{{ t('VUI_LONG_CUNG_CAP_THEM_THONG_TIN') }}</p>

            <div class="space" />

            <text-input :label="t('THUONG_HIEU')" :placeholder="t('NHAP_THUONG_HIEU')" v-model:value="brand" />

            <label>{{ t('HOP_VA_PHU_KIEN_DI_KEM') }}</label>
            <radio-input :text="t('CO')" v-model:ref-value="hasAccessories" :value="t('CO')" />
            <radio-input :text="t('KHONG')" v-model:ref-value="hasAccessories" :value="t('KHONG')" />

            <label>{{ t('HOA_DON_MUA_BAN') }}</label>
            <radio-input :text="t('CO')" v-model:ref-value="hasPurchaseInvoice" :value="t('CO')" />
            <radio-input :text="t('KHONG')" v-model:ref-value="hasPurchaseInvoice" :value="t('KHONG')" />

            <label>{{ t('CON_HAN_BAO_HANH') }}</label>
            <radio-input :text="t('CO')" v-model:ref-value="hasWaranty" :value="t('CO')" />
            <radio-input :text="t('KHONG')" v-model:ref-value="hasWaranty" :value="t('KHONG')" />

            <label>{{ t('HU_HAI_HAY_THAY_THE_BO_PHAN') }}</label>
            <radio-input :text="t('CO')" v-model:ref-value="hasReplacement" :value="t('CO')" />
            <radio-input :text="t('KHONG')" v-model:ref-value="hasReplacement" :value="t('KHONG')" />

            <select-input :label="t('NAM_MUA')" :placeholder="t('CHON_NAM_MUA')" :items="availableYears" v-model:value="yearOfPurchase" />

            <br />

            <primary-button :text="t('GUI_THONG_TIN')" inverse @click="updateInfo()" class="update" />
            <primary-button :text="t('BO_QUA_VA_HOAN_THANH')" @click="skip()" class="skip" />
          </div>

          <div class="form-step done" v-show="step === 3">
            <div class="img">
              <img :src="checkImg" :alt="t('YEU_CAU_DANG_DUOC_XU_LY')" />
            </div>
            <p>{{ t('YEU_CAU_DANG_DUOC_XU_LY') }}</p>
            <p><strong>{{ t('CAM_ON_BAN_DA_CHON_CAMDONHANH') }}</strong></p>

            <primary-button :text="t('TRO_VE_TRANG_CHU')" inverse @click="goToHome()" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router';
import { TweenMax } from 'gsap';

import eligibleItems from '@/consts/eligibleItems';
import { addCommas } from '@/helpers';
import PATHS from '@/consts/paths';

import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';
import CheckInput from '@/components/CheckInput';
import RadioInput from '@/components/RadioInput';
import SelectInput from '@/components/SelectInput';

import orderService from '@/services/order';

import submitOrderBg from '@/assets/imgs/submit-order-bg.png';
import checkImg from '@/assets/imgs/check.png';

export default {
  name: 'SubmitRequest',
  components: {
    TextInput,
    CheckInput,
    SelectInput,
    PrimaryButton,
    RadioInput
  },
  props: {
    overrideStep: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const title = t('GUI_THONG_TIN');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    const thisYear = parseInt(moment().format('YYYY'), 10);
    const availableYears = Array(12).fill().map((item, i) => ({
      value: thisYear - i,
      text: thisYear - i
    }));
    const last = availableYears[availableYears.length - 1].value;
    availableYears.push({
      text: `${t('TRUOC')} ${last}`,
      value: `${t('TRUOC')} ${last}`
    });

    const step = ref(1);

    const order = ref({});

    const name = ref('');
    const amount = ref('');
    const phoneNumber = ref('');
    const assetCategory = ref(null);
    const brand = ref('');
    const hasAccessories = ref(null);
    const hasPurchaseInvoice = ref(null);
    const hasWaranty = ref(null);
    const hasReplacement = ref(null);
    const yearOfPurchase = ref(thisYear);

    const nameInputRef = ref(null);
    const amountInputRef = ref(null);
    const phoneNumberInputRef = ref(null);
    const assetCategoryInputRef = ref(null);
    const acceptTermsAndConditionsInputRef = ref(null);

    const acceptTermsAndConditions = ref(false);
    const termsActive = ref(false);
    const termsHeight = ref(0);
    const termsRef = ref(null);

    const assetCategories = eligibleItems.map((item) => (
      {
        text: item.name,
        value: item.id
      }
    ));

    const isDesktop = computed(() => store.state.app.isDesktop);

    const overrideStep = computed(() => route.query.overrideStep);

    const submit = async () => {
      const inputs = [
        nameInputRef.value,
        phoneNumberInputRef.value,
        assetCategoryInputRef.value,
        acceptTermsAndConditionsInputRef.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        const validInput = input.validateInput();
        valid = valid && validInput;
      });

      if (valid) {
        try {
          const res = await orderService.createOrder({
            firstName: name.value,
            amount: amount.value,
            phoneNumber: phoneNumber.value,
            assetCategoryId: assetCategory.value,
          });

          order.value = res;
          step.value = 2;
        } catch (e) {
          store.dispatch('flash/error', 'ERROR_OCCURRED_PLEASE_TRY_AGAIN');
        }
      }
    };

    const updateInfo = async () => {
      if (!order.value?.id) {
        return;
      }

      try {
        const res = await orderService.updateOrder(order.value.id, {
          description: `
            Thương hiệu: ${brand.value},
            ${t('HOP_VA_PHU_KIEN_DI_KEM')}: ${hasAccessories.value},
            ${t('HOA_DON_MUA_BAN')}: ${hasPurchaseInvoice.value},
            ${t('CON_HAN_BAO_HANH')}: ${hasWaranty.value},
            ${t('HU_HAI_HAY_THAY_THE_BO_PHAN')}: ${hasReplacement.value},
            ${t('NAM_MUA')}: ${yearOfPurchase.value}
          `
        });

        order.value = res;
        step.value = 3;
      } catch (e) {
        store.dispatch('flash/error', 'ERROR_OCCURRED_PLEASE_TRY_AGAIN');
      }
    };

    const formatAmount = (val) => {
      let formatted = addCommas(val, '.');
      if (formatted === '0') formatted = '';

      return formatted;
    };

    const toggleTermsActive = () => {
      termsActive.value = !termsActive.value;

      if (termsActive.value) {
        termsHeight.value = `${termsRef.value.scrollHeight + 40}px`;
        TweenMax.to(window, .3, {
          scrollTo: termsRef.value.offsetTop
        });
      } else {
        termsHeight.value = 0;
      }
    };

    const goToHome = () => {
      router.push(PATHS.HOME);
    };

    const skip = () => {
      step.value = 3;
    };

    onMounted(() => {
      if (route.query.overrideStep) {
        step.value = parseInt(route.query.overrideStep, 10);
      }

      if (route.query.assetCategory) {
        assetCategory.value = parseInt(route.query.assetCategory, 10);
      }

      router.replace({ query: {} });
    });

    watch(step, (val) => {
      TweenMax.to(window, .3, {
        scrollTo: 0
      });
    });

    return {
      t,
      assetCategories,
      name,
      amount,
      phoneNumber,
      assetCategory,
      brand,

      nameInputRef,
      phoneNumberInputRef,
      amountInputRef,
      assetCategoryInputRef,

      submit,
      formatAmount,
      isDesktop,
      submitOrderBg,
      acceptTermsAndConditions,
      acceptTermsAndConditionsInputRef,
      termsActive,
      toggleTermsActive,
      termsHeight,
      termsRef,
      step,

      hasAccessories,
      hasPurchaseInvoice,
      hasWaranty,
      hasReplacement,
      availableYears,
      yearOfPurchase,
      updateInfo,
      checkImg,
      skip,
      goToHome
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#submit-order {
  width: 100%;
  background-color: $raisin;
  padding-top: 90px;
  padding-bottom: 120px;

  @media (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  .main {
    position: relative;
    max-width: 960px;

    .grid {
      background-image: url('~@/assets/imgs/dots.png');
      width: 414px;
      height: 414px;
      position: absolute;
      bottom: -60px;
      left: 0;
      transform: translateX(-50%);
    }

    .main-inner {
      min-height: calc(100vh - 240px);
      margin-left: 5px;
      margin-right: 5px;
    }

    .bg {
      position: relative;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 84px;
      overflow: hidden;

      @media (max-width: 767px) {
        height: 200px;
        border-bottom-left-radius: 0;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .blend {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #350002;
        mix-blend-mode: overlay;
        opacity: .4;
      }
    }

    .form {
      padding: 30px 20px;
      padding-top: 100px;
      background-color: $white;
      transition: all .2s ease-in-out;
      position: relative;
      border-bottom-right-radius: 16px;
      border-top-right-radius: 16px;

      @media (max-width: 767px) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 16px;
      }

      .progress-step {
        position: absolute;
        top: 30px;
        left: 20px;
        width: calc(100% - 40px);
        text-align: center;

        .step {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $white;
          border: 1px solid $raisin;
          text-align: center;
          color: $raisin;
          vertical-align: middle;
          margin: 0 7px;
          font-size: 11px;

          &.current {
            background-color: $redSalsa;
            color: $white;
            border-color: $redSalsa;
          }

          &.done {
            background-color: #09C253;
            color: $white;
            border-color: #09C253;
          }
        }

        .line {
          display: inline-block;
          width: 150px;
          border-top: 1px solid $raisin;
          vertical-align: middle;
          height: 0;
        }
      }

      .form-step {
        &.done {
          text-align: center;
          padding: 50px;

          img {
            width: 120px;
            margin-bottom: 20px;
          }

          @media (max-width: 767px) {
            padding: 0;
            margin-top: -50px;
          }
        }
      }

      h1 {
        font-weight: 700;
        margin-bottom: 15px;
      }

      .space {
        margin-bottom: 30px;
      }

      .input {
        width: 100%;
        margin-bottom: 27px;
      }

      .check-input {
        width: auto;
      }

      .terms-text {
        vertical-align: middle;
      }

      .terms-link {
        text-decoration: underline;
        color: $redSalsa;
        cursor: pointer;
      }

      .terms-content {
        h3 {
          font-size: 18px;
          font-weight: 700;
        }

        font-size: 14px;
        color: $raisin;
        padding: 0 15px;
        border: 0;
        box-sizing: border-box;
        background-color: $snow;
        border-radius: 12px;
        margin-bottom: 0;
        height: 0;
        overflow: hidden;
        transition: all .2s ease-in-out;

        &.active {
          height: auto;
          margin-bottom: 10px;
          padding: 15px;
          border: 1px solid $gainsboro;
        }
      }

      .text-input {
        input {
          border: 1px solid $gainsboro;
        }
      }

      .select-input {
        .display {
          border: 1px solid $gainsboro;
        }
      }

      label {
        font-weight: bold;
        padding-left: 2px;
        color: $raisin;
        font-size: 18px;
        margin-bottom: 10px;
        margin-right: 7px;
        display: block;
      }

      .radio-input {
        margin-right: 15px;
        width: auto;
      }

      .primary-button {

        &.update {
          margin-bottom: 20px;
          margin-right: 15px;
          padding-left: 30px;
          padding-right: 30px;
          width: 180px;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        &.skip {
          margin-bottom: 20px;
          padding-left: 30px;
          padding-right: 30px;
          width: calc(100% - 180px - 15px);

          &::before {
            border-color: $gainsboro;
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
