import ERROR_CODES from '@/consts/errorCodes';

export default class InvalidToken extends Error {
  static code = ERROR_CODES.AUTH_INVALID_TOKEN;

  constructor(message) {
    super(message || 'Invalid token.');
    this.code = ERROR_CODES.AUTH_INVALID_TOKEN;
  }
}
