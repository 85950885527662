import camera from '@/assets/imgs/eligible-items/camera.png';
import phone from '@/assets/imgs/eligible-items/phone.png';
import car from '@/assets/imgs/eligible-items/car.png';
import bike from '@/assets/imgs/eligible-items/bike.png';
import bag from '@/assets/imgs/eligible-items/bag.png';
import watch from '@/assets/imgs/eligible-items/watch.png';
import electronic from '@/assets/imgs/eligible-items/appliance.png';
import gold from '@/assets/imgs/eligible-items/gold.png';
import diamond from '@/assets/imgs/eligible-items/diamond.png';
import estate from '@/assets/imgs/eligible-items/estate.png';
import laptop from '@/assets/imgs/eligible-items/laptop.png';
import desktop from '@/assets/imgs/eligible-items/computer.png';
import jewelry from '@/assets/imgs/eligible-items/jewelry.png';
import art from '@/assets/imgs/eligible-items/art.png';

import cameraProtection from '@/assets/imgs/eligible-items/camera/camera-protection.jpg';
import cameraSeal from '@/assets/imgs/eligible-items/camera/camera-seal.jpg';

import phoneSeal from '@/assets/imgs/eligible-items/phone/phone-seal.jpg';
import phoneSimpleProcedure from '@/assets/imgs/eligible-items/phone/simple-procedure.jpg';

import carSeal from '@/assets/imgs/eligible-items/car/car-seal.jpg';
import carStorage from '@/assets/imgs/eligible-items/car/car-storage.jpg';

import bikeSeal from '@/assets/imgs/eligible-items/bike/bike-seal.jpg';
import bikeSimpleProcudure from '@/assets/imgs/eligible-items/bike/simple-procedure.jpg';
import bikeInterestRate from '@/assets/imgs/eligible-items/bike/interest-rate.png';

import bagSeal from '@/assets/imgs/eligible-items/bag/bag-seal.jpg';
import bagFakeVsAuthentic from '@/assets/imgs/eligible-items/bag/fake-vs-authentic.jpg';

import watchSeal from '@/assets/imgs/eligible-items/watch/watch-seal.jpg';
import watchFakeVsAuthentic from '@/assets/imgs/eligible-items/watch/fake-vs-authentic.jpg';

import diamondSeal from '@/assets/imgs/eligible-items/diamond/diamond-seal.jpg';
import diamondSimpleProcedure from '@/assets/imgs/eligible-items/diamond/simple-procedure.jpg';

import laptopSeal from '@/assets/imgs/eligible-items/laptop/laptop-seal.png';
import laptopSimpleProcedure from '@/assets/imgs/eligible-items/laptop/simple-procedure.jpg';

export default [
  {
    image: camera,
    name: 'Máy ảnh',
    description: 'Tất cả các loại máy ảnh cơ, lens, Nikon, Canon,…',
    slug: 'may-anh',
    content: `
    <h1 style="text-align: justify;">Cầm Đồ Máy Ảnh An Toàn</h1>
    <p style="text-align: justify;">Với sự phát triển của công nghệ hiện đại, Máy ảnh đã trở thành vật dụng rất quen thuộc với giới trẻ, đặc biệt là với những người đam mê nghệ thuật nhiếp ảnh. Cùng với sự phổ biến của nó, máy ảnh cũng trở thành vật dụng được cầm khá phổ biến, và được khá nhiều các tiệm cầm đồ chấp nhận. Tuy nhiên, không phải nơi nào cũng chấp nhận <strong><a href="/">cầm đồ máy ảnh giá cao</a></strong>, hoặc cầm với lãi suất tốt. Rất nhiều tiệm không thể đánh giá đúng giá trị máy ảnh, hoặc đưa ra mức lãi suất vô cùng cao, gây khó khăn cho người đi cầm.</p>
    <h2 style="text-align: justify;">Cầm máy ảnh hãy cẩn thận!</h2>
    <address style="text-align: center;"><img src="${cameraProtection}" alt="Cầm máy ảnh an toàn" width="100%" height="auto"></address>
    <address style="text-align: center;"><em>Máy ảnh cần được bảo quản cẩn thận</em></address>
    <p style="text-align: justify;">Đã không ít người mang máy ảnh tới các cửa hiệu không uy tín để giải quyết vấn đề tài chính và điều đó đã gặp phải những hậu quả như sau:</p>
    <p style="text-align: justify;">- Nhiều trung tâm cầm đồ sau khi nhận máy ảnh từ khách có tính tò mò đã mang máy ra ngó nghiêng và chụp thử trong khi không có chuyên môn.</p>
    <p style="text-align: justify;">- Vì sự hiểu biết về máy ảnh không nhiều nên khi nhận máy lại không biết cách bảo quản là bỏ vào tủ chống ẩm, dẫn tới máy bị chịu tác động mạnh của môi trường cũng như thời tiết dẫn tới máy bị hỏng và giảm chất lượng.</p>
    <p style="text-align: justify;">- Khi mang máy ảnh tới trung tâm cầm đồ không đủ chuyên môn và kiến thức về loại tài sản này nên khi định giá sẽ không chính xác và bạn không nhận số tiền đúng với giá trị của nó.</p>
    <p style="text-align: justify;">- Nhiều địa chỉ không uy tín khi khách đến cầm cố chỉ chờ khách sơ hở không nhớ hạn sẽ thanh lý đồ của khách. Hậu quả mang lại khi cầm máy ảnh tại địa chỉ không uy tín là rất nhiều, chính những điều này đã khiến nhiều người không còn tin vào cầm đồ cũng như khi có nhu cầu cầm đồ sẽ hoang mang không biết nên tới địa chỉ nào.</p>
    <h2 style="text-align: justify;">Nhận biết tiệm cầm đồ uy tín</h2>
    <p style="text-align: justify;">Vì những nguy hiểm có thể xảy ra đó mà khi cầm cố khách hàng hãy thật tỉnh táo lựa chọn những cơ sở kinh doanh uy tín với những đặc điểm sau:</p>
    <p style="text-align: justify;">- Có địa chỉ kinh doanh cụ thể và website bán hàng chuyên nghiệp.</p>
    <p style="text-align: justify;">- Minh bạch về lãi suất và thời hạn cầm cố.</p>
    <p style="text-align: justify;">- Có đội ngũ nhân viên chuyên nghiệp định giá chính xác tài sản của khách hàng.</p>
    <p style="text-align: justify;">- Khi nhận cầm cố thì có hợp đồng với đầy đủ thông tin và giá trị pháp lý.</p>
    <p style="text-align: justify;">- Niêm phong tài sản an toàn nhất.</p>
    <h2 style="text-align: justify;">Cầm đồ máy ảnh ở đâu?</h2>
    <address style="text-align: center;"><img src="${cameraSeal}" alt="Máy ảnh được niêm phong tại Camdonhanh" width="100%" height="auto"></address>
    <address style="text-align: center;"><em>Cầm đồ máy ảnh được niêm phong</em></address>
    <p style="text-align: justify;">Với mô hình hoạt động được xây dựng dựa trên phương thức hoạt động của các tổ chức tài chính nước ngoài, Camdonhanh có quy trình và thủ tục cầm cố cực kỳ đơn giản và chuyên nghiệp.</p>
    <p style="text-align: justify;">Đội ngũ thẩm định của Camdonhanh có khả năng đánh giá đúng chính xác giá trị máy ảnh của khách hàng, và đưa ra <a href="/"><strong>mức cầm đồ thực tế có thể có</strong></a>, giúp khách hàng có thể cầm cố với mức vay cao nhất.</p>
    <p style="text-align: justify;">Hơn thế nữa, do quy trình thủ tục chuyên nghiệp, máy ảnh của khách hàng sẽ được dán tem có chữ ký của khách hàng, đảm bảo quá trình bảo quản, cầm cố, tài sản của khách hàng không bị mang ra sử dụng.</p>
    <p style="text-align: justify;">Camdonhanh còn có nơi bảo quản an toàn, sạch sẽ, đảm bảo được tài sản khách hàng trong thời gian cầm cố vẫn giữ nguyên được chất lượng hoạt động. Bên cạnh đó, Tại Camdonhanh, khách hàng sẽ được cung cấp bản hợp đồng minh bạch, đảm bảo lợi ích cho khách hàng trong thời gian cầm cố. Đó là lý do rất nhiều khách hàng chọn cầm đồ tại Camdonhanh.</p>
    `,
    key: 'CAMERA',
    id: 7
  },
  {
    image: phone,
    name: 'Điện thoại',
    description: 'Các loại điện thoại, máy tính bảng như Iphone, Ipad, Samsung, Oppo, Redmi,…',
    slug: 'dien-thoai',
    content: `
    <h1 style="text-align: justify;">Cầm Đồ Điện Thoại</h1>
    <p style="text-align: justify;">Điện thoại là một trong những vật dụng phổ biến và cần thiết của không chỉ các khách hàng là sinh viên, mà còn của các khách hàng là dân văn phòng. Nhờ vào tính phổ biến của nó, mà khi gặp những khó khăn về tài chính, nhiều khách hàng cũng chọn sử dụng điện thoại như vật dụng cầm cố để giải quyết nhu cầu kinh tế. <a href="/"><strong>Khi cần cầm đồ điện thoại</strong></a>, nhiều khách hàng cảm thấy phân vân không biết chọn cho mình cơ sở cầm đồ nào uy tín, chất lượng giữa hàng trăm các tiệm cầm đồ mọc lên như nấm thì Camdonhanh chính là lựa chọn hoàn hảo cho các khách hàng, bởi 3 ưu điểm vượt trội sau:</p>
    <h2 style="text-align: justify;">Tài Sản Được Đảm Bảo</h2>
    <p style="text-align: justify;"><img src="${phoneSeal}" alt="Cầm đồ điện thoại an toàn" width="100%" height="auto">Khi cầm đồ điện thoại tại Camdonhanh, khách hàng sẽ được cung cấp một hợp đồng cầm cố vô cùng chi tiết, với các hạng mục cầm cố, ghi rõ quyền lợi và trách nhiệm 2 bên, đảm bảo hoàn toàn lợi ích của khách hàng.</p>
    <p style="text-align: justify;">Cùng với hợp đồng cầm cố , khách hàng hoàn toàn có thể yên tâm tài sản của mình được bảo quản kỹ càng, có dán tem niêm phong có chữ ký của khách, đảm bảo&nbsp; 100% không bị sử dụng trong suốt quá trình cầm cố.</p>
    <h2 style="text-align: justify;">Thủ Tục Đơn Giản</h2>
    <p style="text-align: justify;"><img src="${phoneSimpleProcedure}" alt="Thủ tục cầm điện thoại dễ dàng" width="100%" height="auto">Kế thừa phương thức hoạt động tại các tổ chức cầm đồ nổi tiếng trên thế giới, Camdonhanh có quy trình và thủ tục cầm đồ vô cùng đơn giản.</p>
    <p style="text-align: justify;">Khách hàng có thể đến trực tiếp tại Camdonhanh, hoặc chỉ cần thực hiện các bước sau để có thể cầm cố điện thoại :</p>
    <p style="text-align: justify;"><em>Gửi Yêu Cầu</em></p>
    <p style="text-align: justify;">Khách hàng có thể gửi yêu cầu định giá tài sản qua website hoặc Fanpage Camdonhanh với việc chụp ảnh và thông báo chi tiết tài sản cầm cố.</p>
    <p style="text-align: justify;"><em>Nhận Định Giá</em></p>
    <p style="text-align: justify;">Trong vòng 10 phút, sau khi gửi yêu cầu định giá, Camdonhanh sẽ liên hệ và báo giá online cho khách hàng.</p>
    <p style="text-align: justify;"><em>Thẩm Định Và Nhận Tiền</em></p>
    <p style="text-align: justify;">Khách hàng có thể lựa chọn thẩm định tài sản tại nhà hoặc tại các chi nhánh của chúng tôi và nhận tiền ngay sau khi ký hợp đồng cầm cố.</p>
    <p style="text-align: justify;"><em>Hoàn Trả Khoản Vay</em></p>
    <p style="text-align: justify;">Với việc niêm phong tài sản đảm bảo với tem niêm phong. Sau khi kết thúc hợp đồng tài sản được trao lại cho khách hàng y hiện trang ban đầu.</p>
    <h2 style="text-align: justify;">Cầm Đồ Điện Thoại Giá Cao</h2>
    <p style="text-align: justify;">Nhờ đội ngũ thẩm định viên chuyên nghiệp, Camdonhanh sẽ kiểm tra chi tiết cấu hình máy, tình trạng sử dụng của tài sản để đưa ra mức giá chính xác nhất, và cao nhất có thể, hỗ trợ tối đa nhu cầu giải quyết tài chính của khách hàng.</p>
    <p style="text-align: justify;">Với mức lãi suất thấp hơn hẳn những cơ sở cầm đồ khác, Camdonhanh giúp cho khách hàng dễ dàng hơn trong việc trả lãi và lấy lại tài sản sau khi tất toán hợp đồng.<br>Ngoài ra, Camdonhanh khuyên bạn thực hiện những yêu cầu sau để nhận được mức giá tối đa:</p>
    <p style="text-align: justify;"><em>Vệ sinh điện thoại</em></p>
    <p style="text-align: justify;">Với việc vệ sinh máy, điện thoại trông mới hơn và dĩ nhiên sẽ được định giá cao hơn khi cầm cố.</p>
    <p style="text-align: justify;"><em>Chuẩn bị phụ kiện</em></p>
    <p style="text-align: justify;">Phụ kiện các loại điện thoại hiện nay có giá trị khá cao, vì thế việc chuẩn bị đầy đủ hộp, sạc, tai nghe cũng giúp tài sản được cầm với mức giá cao nhất có thể.</p>
    `,
    key: 'CELLPHONE_TABLET',
    id: 4,
    meta: {
      title: '3 Khác Biệt Khi Cầm Đồ Điện Thoại Tại Camdonhanh',
      description: 'Cầm đồ điện thoại được giá cao, tài sản được đảm bảo, thủ tục tiện lợi tại Camdonhanh - Cầm đồ trực tuyến chuyên nghiệp đầu tiên tại Việt Nam.'
    }
  },
  {
    image: car,
    name: 'Xe hơi',
    description: 'Xe 4 chỗ, 7 chỗ, bán tải,… như Ford, Toyota, Audi, BMW, Porche, Lexus,…',
    slug: 'xe-hoi',
    content: `
    <h1 style="text-align: justify;"><strong>Cầm Đồ Oto</strong></h1>
    <p style="text-align: justify;">Với dịch vụ cầm đồ nếu không tìm hiểu kỹ những lưu ý sẽ rất đến nhiều hậu quả không mong muốn. Nhất là khi cầm ô tô - một tài sản có giá trị rất lớn.&nbsp;Cầm đồ ô tô có thể giúp mọi người giải quyết vấn đề tài chính nhanh chóng nhưng bên cạnh đó nếu không biết cách tìm trung tâm cầm đồ uy tín sẽ khiến bạn rơi vào nhiều tình trạng xấu không mong muốn. Để chấm dứt tình trạng này, người đi cầm cố ô tô cần biết cách lựa chọn, cách phân biệt giữa <a href="/"><strong>trung tâm cầm xe oto uy tín</strong></a> và không uy tín.</p>
    <h2 style="text-align: justify;"><strong>Cầm oto uy tín là thế nào ?</strong></h2>
    <address style="text-align: center;"><img src="${carSeal}" alt="cầm ô tô tại địa chỉ uy tín" width="100%" height="auto"></address>
    <address style="text-align: center;"><em>Cầm ô tô uy tín tài sản được đảm bảo 100%</em></address>
    <p style="text-align: justify;">Một cửa hiệu cầm đồ làm ăn uy tín, khi khách đến cầm đồ ô tô được thể hiện ở những điều sau:</p>
    <p style="text-align: justify;">- Chiếc ô tô mà khách cầm đồ được họ liệt kê rõ ràng giá trị và cam kết chịu trách nhiệm trong trường hợp khi khách đến nhận lại đồ không còn nguyên vẹn. Với các trung tâm cầm đồ không uy tín, vấn đề này sẽ được nói rất mập mờ thậm chí là bỏ qua.</p>
    <p style="text-align: justify;">- Nơi Cầm Oto uy tín thường lãi suất sẽ chỉ giữ ở mức đúng quy định của pháp luật hoặc thấp hơn. Nhưng với trung tâm không uy tín lãi suất mà người cầm đồ ô tô sẽ phải trả sẽ cao hơn so với quy định và các cửa hiệu uy tín khác.</p>
    <p style="text-align: justify;">- Cầm đồ uy tín sẽ thẩm định giá ô tô của khách hàng một cách chính xác. Điều này ở cầm đồ không uy tín sẽ không xảy ra, mà thay vào đó khách khi tới cầm đồ tại đây sẽ bị chèn ép ở mức giá thấp nhất.</p>
    <p style="text-align: justify;">- Địa chỉ cầm ô tô uy tín khách sẽ được cung cấp hợp đồng minh bạch lãi suất, cùng với đó là trách nhiệm của 2 bên đều được ghi rõ ràng và cụ thể.</p>
    <h2 style="text-align: justify;">Kinh nghiệm cầm xe ô tô</h2>
    <p style="text-align: justify;">Ô tô là một tài sản có giá trị lớn, vì thế khi cầm đồ ô tô bạn cầm thực sự cẩn trọng và tìm kiếm những địa chỉ uy tín. Kinh nghiệm nhiều người khi cầm đồ ô tô cho biết:</p>
    <p style="text-align: justify;">- Khi đi cầm đồ ô tô trước tiên cần phải xem xét tiệm cầm đồ đó đã hoạt động lâu chưa, có uy tín không có được tin cậy không.</p>
    <p style="text-align: justify;">- Khi mang ô tô đi cầm đồ thì phải cần có hợp đồng hai bên rõ ràng, tránh những trường hợp tranh chấp rủi ro xảy ra trong quá trình cầm cố.</p>
    <p style="text-align: justify;">- Việc cầm xe oto có nhiều trường hợp chủ sẽ bắt khách phải sang giấy tờ xe để thuận tiện cho việc siết nợ. Điều này người đi cầm cố cần lưu ý vì nó rất phức tạp và thậm chí nhiều rủi ro cho mình.</p>
    <h2 style="text-align: justify;">Dịch vụ cầm oto uy tín</h2>
    <p style="text-align: justify;"><img style="font-size: 1em;" src="${carStorage}" alt="cầm ô tô tại Camdonhanh" width="100%" height="auto">Camdonhanh hiện là đơn vị cầm đồ uy tín với nhiều năm kinh nghiệm trong lĩnh vực nghiên cứu và phát triển mô hình công ty tài chính tập trung vào các khách hàng ở khu vực Đông Nam Á đã chỉ ra rằng. Một trung tâm cầm đồ uy tín nói chung và cầm xe ô tô nói riêng sẽ có những chính sách và cam kết như sau:</p>
    <p style="text-align: justify;">- Tài sản được niêm phong đảm bảo 100% không bị sử dụng trong thời gian cầm cố</p>
    <p style="text-align: justify;">- Có hợp đồng cầm cố rõ ràng, có giá trị pháp lý</p>
    <p style="text-align: justify;">- Minh bạch tuyệt đối về lãi suất và quy trình</p>
    <p style="text-align: justify;">- Đem đến sự tiện lợi với dịch vụ định giá trực tuyến và phục vụ tận nhà</p>
    <p style="text-align: justify;">- Thông tin giao dịch khách hàng được bảo mật</p>
    <p style="text-align: justify;">- Lãi suất cầm cố thấp , hạn mức cao</p>
    <p style="text-align: justify;">- Có chính sách ưu đãi về lãi suất đối với khách hàng cầm tài sản giá trị lớn.</p>
    `,
    key: 'AUTOMOBILE',
    id: 2,
    meta: {
      title: 'Cầm Đồ Xe Ô Tô "Tại Nhà" Giá Cao, Lãi Suất Thấp TPHCM',
      description: 'Cầm ô tô, cầm xe ô tô lãi suất thấp giá cao với dịch vụ chuyên nghiệp phục vụ "tại nhà". Tài sản được niêm phong 100% khi cầm cố'
    }
  },
  {
    image: bike,
    name: 'Xe máy',
    description: 'Các loại xe máy như AirBlade, Winner, Sh, Click, Vespa, Vision,…',
    slug: 'xe-may',
    content: `
    <h1 style="text-align: justify;">Cầm Xe Máy Tại Camdonhanh</h1>
    <h2 style="text-align: justify;">Thủ Tục Đơn Giản</h2>
    <p style="text-align: justify;"><img src="${bikeSimpleProcudure}" alt="cầm xe máy tiện lợi tại Camdonhanh" width="100%" height="auto"></p>
    <p style="text-align: justify;">Được xây dựng trên mô hình cầm đồ trực tuyến ở các nước trên thế giới, Camdonhanh cho phép khách hàng cầm đồ xe máy dễ dàng, hiệu quả ngay khi đang ở nhà. Chỉ cần sử dụng thiết bị di động hoặc một chiếc laptop để truy cập internet, khách hàng hoàn toàn có thể kết nối với Camdonhanh và cầm xe máy qua các bước:</p>
    <p style="text-align: justify;"><em>Gửi Yêu Cầu</em></p>
    <p style="text-align: justify;">Khách hàng có thể gửi yêu cầu định giá tài sản qua website hoặc Fanpage Camdonhanh bằng việc gửi ảnh và thông báo chi tiết về tài sản cầm cố.</p>
    <p style="text-align: justify;"><em>Nhận Định Giá</em></p>
    <p style="text-align: justify;">Trong vòng 10 phút, sau khi gửi yêu cầu định giá, Camdonhanh sẽ liên hệ và báo giá online cho khách hàng.</p>
    <p style="text-align: justify;"><em>Thẩm Định Và Nhận Tiền</em></p>
    <p style="text-align: justify;">Khách hàng có thể lựa chọn thẩm định tài sản tại nhà hoặc tại các chi nhánh của chúng tôi và nhận tiền ngay sau khi ký hợp đồng cầm cố.</p>
    <p style="text-align: justify;"><em>Hoàn Trả Khoản Vay</em></p>
    <p style="text-align: justify;">Với việc niêm phong tài sản đảm bảo với tem niêm phong. Sau khi kết thúc hợp đồng tài sản được trao lại cho khách hàng y hiện trang ban đầu.</p>
    <h2><a href="/">Xe&nbsp;máy được đảm bảo</a></h2>
    <p style="text-align: justify;"><a href="/"><img src="${bikeSeal}" alt="Cầm đồ xe máy an toàn" width="100%" height="auto"></a></p>
    <p style="text-align: justify;">Nếu đa phần các cơ sở cầm đồ tại Tphcm "KHÔNG" công khai lãi suất, cũng như không có một hợp đồng nào cho khách hàng ,phần lớn chỉ có biên lai ghi nhận không có giá trị pháp lý.</p>
    <p style="text-align: justify;">Tại Camdonhanh,khi cầm xe máy khách hàng sẽ được cung cấp hợp đồng cầm cố, trong đó, các điều khoản hợp đồng sẽ quy định rõ ràng quyền và trách nhiệm 2 bên, các lãi suất minh bạch, cách thức tất toán hợp đồng, đảm bảo hoàn toàn quyền lợi khách hàng.</p>
    <p style="text-align: justify;">Ngoài ra, sau khi ký hợp đồng, tài sản được niêm phong với chữ ký khách hàng, đảm bảo 100% xe máy không bị sử dụng hay thay thế bất kỳ thiết bị nào trong thời gian cầm cố.</p>
    <h2 style="text-align: justify;"><strong>Định giá cầm xe máy cao nhất</strong></h2>
    <p style="text-align: justify;">Do đội ngũ thẩm định viên chuyên nghiệp, kiểm tra và đánh giá đúng chất lượng tài sản, đặc biệt là xe máy, Camdonhanh luôn đưa ra mức vay cao nhất thường là tới 90% giá trị hiện tại của tài sản cho khách hàng cầm xe máy, đảm bảo hỗ trợ được nhu cầu giải pháp tài chính của khách hàng ngay khi cần thiết. Chưa kể, thời gian giải ngân của Camdonhanh cực kỳ nhanh, giúp khách hàng có thể nhận được khoản vay ngay lập tức.</p>
    <h2 style="text-align: justify;"><strong>Lãi suất cầm xe thấp nhất</strong></h2>
    <p style="text-align: justify;"><img src="${bikeInterestRate}" alt="" width="100%" height="auto"></p>
    <p style="text-align: justify;">Khi cầm xe máy lãi suất luôn là điều mà khách hàng cần chú tâm nhất. Không như đa phần các tiệm cầm đồ truyền thống khác với lãi suất cầm đồ xe máy lên tới 10% 1 tháng, khiến nhiều khách hàng đi cầm đồ phải lao đao với gánh nặng trả lãi.&nbsp;</p>
    <p style="text-align: justify;">Cầm xe máy tại Camdonhanh, khách hàng có thể an tâm về lãi suất, bởi lãi suất cầm cố tại đây cực thấp từ 1.75%. Đặc biệt, lãi suất thỏa thuận với khách hàng cũng chính là lãi suất được ghi trên hợp đồng, không có các lãi suất cộng thêm sau này.</p>
    `,
    key: 'MOTORCYCLE',
    id: 1,
    meta: {
      title: 'Cầm đồ xe máy với 4 ưu điểm cùng Camdonhanh',
      description: 'Cầm đồ xe máy được giá cao, lãi suất thấp, uy tín nhất chỉ có tại Camdonhanh. Camdonhanh là công ty với dịch vụ cầm đồ trực tuyến chuyên nghiệp đầu tiên tại Tphcm.'
    }
  },
  {
    image: bag,
    name: 'Túi xách',
    description: 'Túi xách, ví, balo, vali của các thương hiệu như: LV, Hermes, Gucci, Chanel, YSL,…',
    slug: 'tui-xach',
    content: `
    <h1 style="text-align: justify;">Cầm đồ túi xách hàng hiệu</h1>
    <p style="text-align: justify;">Ngày nay tại tphcm, không thiếu các khách hàng sử dụng các túi xách hàng hiệu nổi tiếng trên thế giới như Hermes, Channel,...Khi gặp các vấn đề khó khăn về tài chính, những loại túi xách hàng hiệu này cũng được sử dụng làm tài sản cầm cố hệt như ô tô, xe máy vì giá trị thực tế của nó rất cao. Tuy nhiên, không phải cơ sở cầm đồ nào cũng đủ khả năng để định giá được giá trị của những mặt hàng high-class như thế, cũng như không phải nơi đâu, sau khi cầm cố, khách hàng còn nhận được nguyên vẹn sản phẩm của mình, mà không bị đánh tráo, làm giả. Bài viết này sẽ giúp cho các khách hàng tìm ra cho mình địa chỉ cầm đồ hàng hiệu uy tín, an toàn, giá tốt.</p>
    <h2>Thực Trạng Cầm Túi Xách</h2>
    <p style="text-align: center;"><img src="${bagFakeVsAuthentic}" alt="thực trạng túi xách hiện nay" width="100%" height="auto"><em><br></em></p>
    <p style="text-align: justify;">Rất nhiều khách hàng than phiền vì gặp khó khăn khi <a href="/"><strong>cầm đồ hàng hiệu</strong></a>. Khó khăn đầu tiên chính là không dễ để tìm ra nơi chấp nhận cầm túi xách. Rõ ràng giá trị tài sản rất cao, tài sản có đủ mã vạch và chuẩn authentic, nhưng lại luôn bị từ chối khi đi cầm. Lý do đơn giản là nhiều nơi không đủ chuyên môn để thẩm định mặt hàng high-class như thế này, cũng như không đủ chuyên môn để định giá chính xác sản phẩm, sợ bị lừa gạt,...</p>
    <p style="text-align: justify;">Khó khăn thứ 2 chính là nếu chấp nhận cầm đồ, thì nhiều nơi lại trả giá rất thấp, và đòi lãi suất quá cao, do họ biết rõ khách hàng đang khó khăn, và rất khó để tìm ra nơi chấp nhận cầm. Chính vì vậy mà nhiều tiệm cầm đồ đã ép giá hết mức có thể. Khó khăn thứ 3 càng nguy hại hơn, đó là khi đã được chấp nhận cầm, nhiều nơi còn trưng dụng tài sản, hoặc thậm chí, làm giả sản phẩm, kể cả mã code. Khách hàng thường không để ý và chỉ phát giác ra khi cần cầm tiếp, hoặc khi cần bán lại sản phẩm. Khi đó, thiệt hại của khách không phải chỉ là lỗ vài chục triệu do giá cầm thấp, mà còn là lỗ cả trăm triệu do bị đánh tráo tài sản.</p>
    <h2 style="text-align: justify;">Nên Chọn Địa Chỉ Uy Tín?</h2>
    <p style="text-align: justify;">Với 3 khó khăn nêu trên, thực sự quá khó khăn cho khách hàng để tìm ra 1 nơi cầm đồ túi xách hàng hiệu uy tín. Nếu cầm đồ uy tín một chút, thì lãi suất lại quá cao. Nếu lãi suất thấp 1 chút, thì nguy cơ bị trưng dụng, làm giả cũng cao không kém. Do đó, khi cầm túi xách, khách hàng nên chọn địa chỉ cầm đồ thực sự uy tín, để tránh phát sinh các nguy cơ sau này.<br>Ngoài ra, ở các cơ sở cầm đồ không uy tín thường mức lãi suất cầm túi xách họ đưa ra rất cao , có thể lên tới 10-15%/ tháng . Khách hàng khi cầm cố tài sản ở đây sẽ phải chịu một gánh nặng tài chính vô cùng lớn có thể mất khả năng chi trả sau khi cầm đồ , sau cùng là ngậm ngùi để mất tài sản vào tay các cơ sở thiếu uy tín này.</p>
    <h2>Địa Chỉ Uy Tín Nhất?</h2>
    <p><img src="${bagSeal}" alt="Cầm túi xách được đảm bảo" width="100%" height="auto"></p>
    <p style="text-align: justify;">Hiện tại, khi nghĩ tới cầm đồ túi xách, cầm đồ hàng hiệu tại tphcm, nhiều người nghĩ ngay đến Camdonhanh. Đây là một địa chỉ cầm đồ hàng hiệu uy tín, được truyền tai bởi rất nhiều người. Do được thành lập dựa trên quỹ đầu tư John Galt Venture của nước ngoài, Camdonhanh được xây dựng theo mô hình cực kỳ chuyên nghiệp của các tổ chức tài chính trên thế giới, nhân viên thẩm định có đủ chuyên môn cao để giám định, đánh giá tài sản hàng hiệu.<br>Do đó, khi cầm túi xách tại đây, các khách hàng sẽ nhận được giá cầm cao nhất dựa trên giá trị tài sản, cũng như lãi suất khá thấp, thậm chí có thể xem là thấp nhất tại tphcm. Khi cầm, Camdonhanh sẽ cung cấp một hợp đồng cầm cố minh bạch, rõ ràng, cũng như dán tem niêm phong sản phẩm có chữ ký của khách, giúp khách hàng yên tâm là sản phẩm của mình sẽ không bị trưng dụng, hay thay thế. Do đó, Camdonhanh chính là giải pháp cầm đồ túi sách hữu hiệu nhất TP. HCM.</p>
    `,
    key: 'HANDBAG',
    id: 11,
    meta: {
      title: 'Cầm đồ túi xách hàng hiệu giá cao uy tín tại TPHCM',
      description: 'Camdonhanh nhận cầm đồ túi xách hàng hiệu như Hermes, Channel, LV, Gucci… với lãi suất thấp, hạn mức cao nhất'
    }
  },
  {
    image: watch,
    name: 'Đồng hồ',
    description: 'Patek Philippe, Hublot, Rolex,…',
    slug: 'dong-ho',
    content: `
    <h1 style="text-align: justify;"><strong>Cầm Đồng Hồ Cao Cấp</strong></h1>
    <p style="text-align: justify;">Một trong những tài sản có giá trị cao thường được các khách hàng cầm cố, ngoài Ô tô, Kim Cương chính là Đồng hồ cao cấp. Khi cầm cố khách hàng sẽ nhận được khoản vay từ chục cho tới hàng trăm triệu đồng, tuy nhiên, không phải tiệm cầm đồ nào hiện nay cũng nhận cầm đồng hồ, hay có đủ chuyên môn để đánh giá đúng giá trị đồng hồ, nhiều địa chỉ có cầm đồng hồ với điều kiện cần tháo tung chiếc đồng hồ cao cấp của khách hàng. Điều này khiến cho việc cầm đồ đồng hồ cao cấp của khách hàng không còn hiệu quả, tài sản không còn được đảm bảo 100%.</p>
    <h2 style="text-align: justify;">90% Đồng Hồ Giả, Hãy Cẩn Thận!</h2>
    <p style="text-align: justify;"><img src="${watchFakeVsAuthentic}" alt="Đồng hồ giả thật rất khó phân biệt" width="100%" height="auto">Theo thống kê, mặt hàng đồng hồ được làm giả rất nhiều tại Việt Nam, đồng hồ giả xuất xứ tại Trung Quốc có thể làm giống mẫu mã thật đến hơn 90%. Do đó, sau khi cầm cố, nhiều khách hàng đã tá hỏa, khi nhận ra chiếc đồng hồ của mình đã bị tiệm cầm đồ tráo đổi, sau khi mang đồng hồ đi sửa, hoặc đi bán lại.<br>Như vậy, từ chiếc đồng hồ trị giá hơn trăm triệu đồng, thì khách hàng chỉ nhận lại được chiếc đồng hồ chưa đến 200 ngàn. Do đó, khách hàng cần cần thận trong khi mang đồng hồ thế chấp, đặc biệt là những nơi cầm đồ không uy tín, vì đồng hồ của khách hàng có thể bị làm giả, hoặc thay thiết bị bất cứ lúc nào mà chính bạn cũng không hề hay biết.</p>
    <h2 style="text-align: justify;">Nhận Biết Tiệm Cầm Đồ Uy Tín</h2>
    <p style="text-align: justify;"><img src="${watchSeal}" alt="Cầm đồng hồ uy tín " width="100%" height="auto">Vì những nguy hiểm có thể xảy ra đó mà khi cầm cố khách hàng hãy thật tỉnh táo lựa chọn những cơ sở cầm đồ uy tín với những đặc điểm sau:</p>
    <p style="text-align: justify;">- Có địa chỉ kinh doanh cụ thể và website bán hàng chuyên nghiệp.</p>
    <p style="text-align: justify;">- Minh bạch về lãi suất và thời hạn cầm cố.</p>
    <p style="text-align: justify;">- Có đội ngũ nhân viên chuyên nghiệp định giá chính xác tài sản của khách hàng.</p>
    <p style="text-align: justify;">- Khi nhận cầm cố thì có hợp đồng với đầy đủ thông tin và giá trị pháp lý.</p>
    <p style="text-align: justify;">- Niêm phong tài sản cầm cố an toàn nhất.</p>
    <h2 style="text-align: justify;">Cầm Đồ Đồng Hồ Ở Đâu?</h2>
    <p style="text-align: justify;">Hiện nay, Camdonhanh được xem là dịch vụ cầm đồ uy tín nhất với đội ngũ nhân viên thẩm định chuyên môn cao có thể thẩm định chính xác giá trị đồng hồ của bạn.<br>Ngoài ra, tại Camdonhanh, khách hàng khi cầm cố sẽ được giao một hợp đồng cầm cố có đầy đủ các điều khoản, cũng như quy định rõ ràng về việc bảo quản, chuộc lại tài sản. Hơn thế nữa, Camdonhanh còn có niêm phong dán tem có chữ ký khách hàng, để đảm bảo tài sản không bị tráo và thay đổi.</p>
    `,
    key: 'WATCH',
    id: 12,
    meta: {
      title: 'Cầm đồng hồ cao cấp được giá cao lại an toàn TPHCM',
      description: 'Cầm đồ đồng hồ chuyên nghiệp, uy tín, an toàn, lãi suất thấp nhất chỉ có tại Camdonhanh. Cầm rất nhiều dòng đồng hồ cao cấp khác nhau như Rolex, Patek philippe, Omega, Hublot...'
    }
  },
  {
    image: electronic,
    name: 'Thiết bị gia dụng',
    description: 'Các loại Smart TV hiện hành.',
    slug: 'thiet-bi-gia-dung',
    content: `
    <p style="text-align: justify;">Chỉ cần chụp ảnh đồ gia dụng của bạn kèm theo các thông tin về sản phẩm và gửi cho chúng tôi, bạn sẽ nhận được định giá ngay trong vòng 15 phút. Nếu bạn hài lòng với mức giá<a href="/"> Camdonhanh</a> đưa ra bạn có thể chọn giao dịch với chúng tôi tại nhà hoặc tại văn phòng.</p>
    <p style="text-align: justify;">Tài sản sẽ được thẩm định viên xác nhận lại tình trạng trước khi niêm phong đảm bảo và ký hợp đồng cầm cố. Bạn nhận được khoản vay chỉ trong vòng 30 phút. Camdonhanh sẽ chỉ yêu cầu bạn xuất trình CMND hay hộ chiếu, ngoài ra không cần bất kỳ giấy tờ nào khác. Nếu bạn có hóa đơn mua hàng và phụ kiện đi kèm, chúng tôi khuyến khích bạn mang theo để nhận được định giá cao nhất.</p>
    `,
    key: 'HOUSEHOLD_APPLIANCE',
    id: 10,
    meta: {
      title: 'Cầm đồ gia dụng giá cao, an toàn tại Camdonhanh',
      description: 'Bạn cần tiền? Cầm đồ gia dụng online với dịch vụ Camdonhanh.vn. Giải quyết nhanh vấn đề tài chính của bạn với dịch vụ cầm đồ online chuyên nghiệp đầu tiên tại Việt Nam.'
    }
  },
  {
    image: gold,
    name: 'Vàng, trang sức',
    description: 'Vàng và các trang sức.',
    slug: 'vang-trang-suc',
    content: `
    <h2 style="text-align: justify;">Cầm Vàng Lãi Suất Thấp</h2>
    <p style="text-align: justify;">Cầm vàng, trang sức ở Camdonhanh, chỉ cần chụp ảnh tài sản kèm theo thông tin và giấy kiểm định (nếu có) gửi cho Camdonhanh, bạn sẽ nhận được định giá chỉ trong vòng 15 phút. Nếu bạn hài lòng với mức giá Camdonhanh đưa ra bạn có thể chọn giao dịch với chúng tôi tại nhà hoặc tại văn phòng. Tài sản sẽ được thẩm định viên xác nhận lại tình trạng trước khi niêm phong đảm bảo và ký hợp đồng cầm cố. Bạn nhận được khoản vay chỉ trong vòng 30 phút. Camdonhanh sẽ chỉ yêu cầu bạn xuất trình CMND hay hộ chiếu, ngoài ra không cần bất kỳ giấy tờ nào khác. Nếu bạn có hóa đơn mua hàng và phụ kiện đi kèm, chúng tôi khuyến khích bạn mang theo để nhận được định giá cao nhất.</p>
    `,
    key: 'GOLD_JEWELRY',
    id: 5
  },
  {
    image: diamond,
    name: 'Kim cương',
    description: 'Kim cương với giấy kiểm định GIA hoặc giấy của PNJ.',
    slug: 'kim-cuong',
    content: `
    <h1 style="text-align: center;">Cầm Đồ Kim Cương</h1>
    <p style="text-align: justify;">Nếu những cơ sở cầm đồ truyền thông không thể đánh giá chính xác chất lượng viên kim cương của bạn, cầm kim cương ở camdonhanh.vn giúp bạn định giá chính xác tài sản của mình cũng như bảo đảm an toàn cho chính tài sản ấy. Cầm kim cương ở chúng tôi có thể khiến bạn hoàn toàn an tâm và tự tin với mức lãi suất thấp nhất cùng chất lượng dịch vụ tuyệt vời. Muốn cầm đồ ư? Đã có chúng tôi đây!</p>
    <h2 style="text-align: justify;">Tại sao chọn Camdonhanh?</h2>
    <p><img src="${diamondSimpleProcedure}" alt="" width="100%" height="auto"></p>
    <h3 style="text-align: justify;"><strong><em>Thủ tục đơn giản</em></strong></h3>
    <p style="text-align: justify;">Cầm cố kim cương ở Camdonhanh, chỉ cần chụp ảnh tài sản kèm theo thông tin và giấy kiểm định gửi cho Camdonhanh, bạn sẽ nhận được định giá chỉ trong vòng 15 phút. Nếu bạn hài lòng với mức giá Camdonhanh đưa ra bạn có thể chọn giao dịch với chúng tôi tại nhà hoặc tại văn phòng.</p>
    <h3 style="text-align: justify;"><strong><em>Tài sản được đảm bảo</em></strong></h3>
    <p style="text-align: justify;">Sau khi ký hợp đồng với Camdonhanh tài sản được niêm phong với tem vỡ đảm bảo 100% không bị chiếm dụng trong thời gian cầm cố tại đây.</p>
    <h3 style="text-align: justify;"><strong><em>Định giá cao</em></strong></h3>
    <p style="text-align: justify;">Với đội ngũ thẩm định am hiểu thị trường, giá cầm kim cương sẽ được định giá cao nhất, phù hợp với nhu cầu tài chính của khách hàng.</p>
    <h3 style="text-align: justify;"><strong><em>Minh bạch</em></strong></h3>
    <p style="text-align: justify;">Khác với những tiệm cầm đồ truyền thống, Cầm kim cương tại Camdonhanh, khách hàng được cung cấp một bản hợp đồng với đầy đủ thông tin lãi suất, thông tin tài sản và cam kết với tài sản cầm cố.</p>
    <h2 style="text-align: justify;">Camdonhanh có gì khác biệt?</h2>
    <p><img src="${diamondSeal}" alt="" width="100%" height="auto"></p>
    <p style="text-align: justify;">Camdonhanh xuất phát từ ý tưởng thay đổi định kiến về dịch vụ cầm đồ, khiến dịch vụ này trở nên an toàn và tiện lợi hơn cho mọi khách hàng. Khách hàng không còn phải đến các tiệm cầm đồ với tâm lý e ngại về lãi suất cao và tài sản không đảm bảo, thay vào đó, camdonhanh hứa hẹn mang đến sự phục vụ tốt nhất với mô hình hỗ trợ tài chính hợp pháp. Camdonhanh cải thiện và tạo ra sự khác biệt cho dịch vụ cầm đồ truyền thống: <br>- Camdonhanh đem đến sự tiện lợi cho bạn với hình thức định giá tài sản qua hình ảnh và thông tin online.</p>
    <p style="text-align: justify;">- Bạn có thể cầm tài sản với giá trị lên đến 80% giá trị hiện thời.</p>
    <p style="text-align: justify;">- Camdonhanh cam kết mức lãi suất có thấp nhất cho bạn.</p>
    <p style="text-align: justify;">- Bạn có thể lựa chọn đến văn phòng hoặc để Camdonhanh phục vụ bạn tận nhà.</p>
    <p style="text-align: justify;">- Tài sản của bạn sẽ được niêm phong cẩn thận và bảo quản an toàn với quy trình chuyên nghiệp.</p>
    <p style="text-align: justify;">- Camdonhanh cam kết với bạn tuân thủ quy trình làm việc minh bạch giữa 2 bên.</p>
    <p style="text-align: justify;">- Chỉ cần một thiết bị kết nối internet bạn có thể yêu cầu dịch vụ mọi lúc mọi nơi.</p>
    `,
    key: 'DIAMOND',
    id: 6,
    meta: {
      title: 'Cầm đồ kim cương xoay sở nhanh khó khăn tài chính',
      description: 'Cầm đồ kim cương , nữ trang kim cương với Camdonhanh quá an toàn, bảo mật với hạn mức cao lãi suất thấp nhất.'
    }
  },
  {
    image: estate,
    name: 'Nhà đất',
    description: 'Nhà đất, căn hộ chung cư,…',
    slug: 'nha-dat',
    content: '',
    key: 'REAL_ESTATE',
    id: 8,
  },
  {
    image: laptop,
    name: 'Máy tính',
    description: 'Các loại máy tính như Macbook, MSI, Asus, Dell, Vaio,…',
    slug: 'may-tinh',
    content: `
    <h1 style="text-align: justify;">Cầm Đồ Laptop Tại Camdonhanh</h1>
    <p style="text-align: justify;">Laptop (Máy tính xách tay) là một trong những vật dụng phổ biến và cần thiết của không chỉ các khách hàng là sinh viên, mà còn của các khách hàng là dân văn phòng. Nhờ vào tính phổ biến của nó, mà khi gặp những khó khăn về tài chính, nhiều khách hàng cũng chọn sử dụng laptop như vật dụng cầm cố để giải quyết nhu cầu kinh tế. Khi cần cầm đồ laptop,cầm máy tính bàn nhiều khách hàng cảm thấy phân vân không biết chọn cho mình <a href="/"><strong>cơ sở cầm đồ nào uy tín</strong></a>, chất lượng giữa hàng trăm các tiệm cầm đồ mọc lên như nấm thì Camdonhanh chính là lựa chọn hoàn hảo cho các khách hàng, bởi 3 ưu điểm vượt trội sau:</p>
    <h2 style="text-align: justify;">Minh Bạch Và An Toàn</h2>
    <p><img style="display: block;" src="${laptopSeal}" alt="Cầm laptop uy tín" width="100%" height="auto">Khi cầm đồ laptop, máy tính bàn tại Camdonhanh, khách hàng sẽ được cung cấp một hợp đồng cầm cố vô cùng chi tiết, với các hạng mục cầm cố, ghi rõ quyền lợi và trách nhiệm 2 bên, đảm bảo hoàn toàn lợi ích của khách hàng. Không những vậy, khách hàng hoàn toàn có thể yên tâm về tài sản của mình được bảo quản kỹ càng, có dán tem niêm phong có chữ ký của khách, đảm bảo không bị sử dụng hay thay thế thiết bị trong suốt quá trình cầm cố. Ngoài ra, khi cầm laptop, Camdonhanh không tiết lộ thông tin cá nhân cho các tổ chức bên ngoài trừ khi khách hàng đồng ý hoặc pháp luật yêu cầu.</p>
    <p style="text-align: justify;">Camdonhanh cam kết khi cầm đồ laptop tại đây:</p>
    <p style="text-align: justify;">- 100% Tài sản được niêm phong an toàn: Camdonhanh cam kết tài sản được niêm phong bằng tem vỡ đảm bảo 100% "KHÔNG" bị sử dụng trong thời gian cầm cố.</p>
    <p style="text-align: justify;">- 100% Thông tin khách hàng được bảo mật: Thông tin khách hàng sẽ được bảo mật tối đa khi cầm cố tại Camdonhanh.</p>
    <p style="text-align: justify;">- Có hợp đồng minh bạch lãi suất: Hợp đồng cầm cố tại Camdonhanh sẽ mô tả tình trạng tài sản, mức lãi suất rõ ràng.</p>
    <h2 style="text-align: justify;">Thủ tục đơn giản</h2>
    <address style="text-align: center;"><img src="${laptopSimpleProcedure}" alt="thủ tục cầm đồ đơn giản" width="100%" height="auto"></address>
    <p style="text-align: justify;">Kế thừa phương thức hoạt động tại các tổ chức cầm đồ nổi tiếng trên thế giới, Camdonhanh có quy trình và thủ tục cầm đồ vô cùng đơn giản. Khi cầm cầm đồ Laptop, máy tính bàn tại Camdonhanh khách hàng chỉ cần thực hiện một vài bước đơn giản sau đây:</p>
    <p style="text-align: justify;">- Đăng ký cầm máy tính trực tuyến với thiết bị di động có kết nối internet tại Camdonhanh.vn</p>
    <p style="text-align: justify;">- Nhận định giá từ Camdonhanh "tại nhà"</p>
    <p style="text-align: justify;">- Nếu khách hàng đồng ý với giá cầm tại đây thì mới tiến hành giao dịch</p>
    <p style="text-align: justify;">- Sau khi khí hợp đồng cầm laptop, tài sản sẽ được niêm phong với tem vỡ và lưu kho an toàn.</p>
    <h2 style="text-align: justify;">Cầm Đồ Laptop Giá Cao</h2>
    <p style="text-align: justify;">Nhờ đội ngũ thẩm định viên chuyên nghiệp, Camdonhanh sẽ kiểm tra chi tiết cấu hình máy, tình trạng sử dụng của tài sản để đưa ra mức giá chính xác nhất, và cao nhất có thể, hỗ trợ tối đa nhu cầu giải quyết tài chính của khách hàng. Ngoài ra, Với mức lãi suất cầm laptop thấp hơn hẳn những cơ sở cầm đồ khác, Camdonhanh giúp cho khách hàng dễ dàng hơn trong việc trả lãi và lấy lại tài sản.</p>
    <p style="text-align: justify;">Ngoài ra, để có được mức giá cao nhất có thể khi cầm đồ Camdonhanh khuyến khích khách hàng nên:</p>
    <p style="text-align: justify;">- Vệ sinh laptop trước khi cầm cố: Việc vệ sinh sẽ giúp tài sản của bạn trở nên mới hơn và tất nhiên luôn được định giá cao hơn.</p>
    <p style="text-align: justify;">- Chuẩn bị đầy đủ phụ kiện: Với việc đầy đủ phụ kiện đặc biệt với cụm từ "fullbox" laptop của khách hàng sẽ có giá nhỉnh hơn so với những chiếc máy không đầy đủ phụ kiện.</p>
    <p style="text-align: justify;">- Dò giá thị trường không bị ép giá: Tất nhiên, khi đi cầm bất kỳ tài sản nào khách hàng cũng nên tìm hiểu giá trị hiện tại của tài sản, tránh tới những địa chỉ cầm đồ rồi nhận được mức giá không phù hợp với nhu cầu bản thân.</p>
    `,
    key: 'COMPUTER',
    id: 14,
    meta: {
      title: 'Cầm Máy Tính Bàn Với 3 "Ưu Điểm Nổi Bật" Tại TPHCM',
      description: 'Cầm đồ trực tuyến Camdonhanh nhận cầm đồ Laptop, cầm máy tính bàn giá cao, lãi suất thấp lại an toan. Nhận cầm cố tất cả các dòng laptop như macbook, Dell, Asus, HP, Acer...'
    }
  },
  {
    image: art,
    name: 'Khác',
    description: 'Bạn vẫn có tài sản khác cần được hỗ trợ? Liên hệ ngay với Camdonhanh để được tư vấn trực tiếp.',
    key: 'OTHER',
    id: 13
  },
];
