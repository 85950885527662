<template>
  <div class="logo-text" title="Camdonhanh">
    <svg width="136" height="17" viewBox="0 0 136 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0663452 10.9929C0.0663452 7.85505 2.52247 5.22607 5.74084 5.22607C7.85818 5.22607 9.12859 6.07413 10.1449 7.1766L9.04389 8.36388C8.19696 7.43102 7.18063 6.66777 5.65614 6.66777C3.4541 6.66777 1.67553 8.53349 1.67553 10.9081V10.9929C1.67553 13.3674 3.4541 15.3179 5.74084 15.3179C7.18063 15.3179 8.19696 14.6395 9.12859 13.7066L10.1449 14.7243C9.0439 15.9964 7.68879 16.8444 5.65614 16.8444C2.52247 16.6748 0.0663452 14.0459 0.0663452 10.9929Z" fill="#141418"/>
      <path d="M12.0929 13.1978C12.0929 10.8232 14.0409 9.55116 16.8358 9.55116C18.2755 9.55116 19.2072 9.72077 20.2235 10.06V9.72077C20.2235 7.77024 19.0378 6.75258 16.9204 6.75258C15.65 6.75258 14.6337 7.0918 13.5327 7.51583L13.0245 6.15894C14.2102 5.5653 15.396 5.22607 17.0051 5.22607C18.5296 5.22607 19.8 5.6501 20.5623 6.41335C21.3245 7.1766 21.748 8.27908 21.748 9.63597V16.4204H20.1388V14.8091C19.3766 15.8268 18.1062 16.6748 16.1582 16.6748C14.1255 16.6748 12.0929 15.4876 12.0929 13.1978ZM20.1388 12.3498V11.3321C19.2919 11.0777 18.2755 10.8232 16.9204 10.8232C14.8878 10.8232 13.7021 11.7561 13.7021 13.0282V13.113C13.7021 14.4699 14.9725 15.3179 16.497 15.3179C18.5296 15.3179 20.1388 14.1307 20.1388 12.3498Z" fill="#141418"/>
      <path d="M25.2206 5.39529H26.8298V7.26101C27.592 6.15854 28.5236 5.14087 30.3869 5.14087C32.1655 5.14087 33.3512 6.07373 33.9441 7.34582C34.7063 6.15854 35.892 5.14087 37.84 5.14087C40.3808 5.14087 41.9053 6.83698 41.9053 9.55076V16.42H40.2961V9.97479C40.2961 7.85465 39.1951 6.66737 37.4165 6.66737C35.7226 6.66737 34.3675 7.93946 34.3675 10.0596V16.42H32.7583V9.88999C32.7583 7.85465 31.6573 6.58257 29.9634 6.58257C28.1849 6.58257 26.9145 8.02426 26.9145 10.0596V16.3352H25.3053V5.39529H25.2206Z" fill="#141418"/>
      <path d="M44.8694 10.908C44.8694 7.26139 47.4949 5.14125 50.1204 5.14125C52.1531 5.14125 53.5082 6.24372 54.3551 7.51581V0.816162H55.9643V16.4204H54.3551V14.2155C53.4235 15.5723 52.1531 16.6748 50.1204 16.6748C47.4949 16.6748 44.8694 14.5547 44.8694 10.908ZM54.4398 10.908C54.4398 8.36387 52.4919 6.58295 50.4592 6.58295C48.3419 6.58295 46.5633 8.19425 46.5633 10.8232V10.908C46.5633 13.537 48.3419 15.1483 50.4592 15.1483C52.4919 15.2331 54.4398 13.4522 54.4398 10.908Z" fill="#141418"/>
      <path d="M59.0133 10.9929C59.0133 7.85505 61.4694 5.22607 64.7725 5.22607C68.0755 5.22607 70.5316 7.85505 70.5316 10.9081V10.9929C70.5316 14.1307 68.0755 16.7596 64.7725 16.7596C61.3847 16.6748 59.0133 14.0459 59.0133 10.9929ZM68.8378 10.9929C68.8378 8.6183 67.0592 6.66777 64.7725 6.66777C62.401 6.66777 60.7072 8.6183 60.7072 10.9081V10.9929C60.7072 13.3674 62.4857 15.3179 64.7725 15.3179C67.1439 15.2331 68.8378 13.2826 68.8378 10.9929Z" fill="#141418"/>
      <path d="M73.0726 4.97121H76.291V6.58252C77.0532 5.64966 77.9849 4.7168 79.6788 4.7168C82.1349 4.7168 83.5747 6.3281 83.5747 8.95708V16.3352H80.3563V9.97475C80.3563 8.44825 79.5941 7.68499 78.4083 7.68499C77.2226 7.68499 76.3757 8.44825 76.3757 9.97475V16.3352H73.1573V4.97121H73.0726Z" fill="#141418"/>
      <path d="M86.3694 0.816162H89.5878V6.58295C90.35 5.65008 91.2816 4.71722 92.9755 4.71722C95.4316 4.71722 96.8714 6.32853 96.8714 8.95751V16.3356H93.6531V9.97517C93.6531 8.44867 92.8908 7.68542 91.7051 7.68542C90.4347 7.68542 89.6725 8.44867 89.6725 9.97517V16.3356H86.4541V0.816162H86.3694Z" fill="#141418"/>
      <path d="M98.9041 13.113C98.9041 10.5688 100.767 9.38151 103.478 9.38151C104.663 9.38151 105.426 9.55112 106.272 9.89034V9.72073C106.272 8.36384 105.426 7.60059 103.816 7.60059C102.546 7.60059 101.699 7.85501 100.683 8.19423L99.8358 5.73486C101.106 5.14122 102.292 4.802 104.24 4.802C106.018 4.802 107.289 5.31084 108.136 6.07409C108.983 6.92214 109.406 8.19423 109.406 9.72073V16.3356H106.272V15.0635C105.51 15.9115 104.409 16.5052 102.8 16.5052C100.683 16.6748 98.9041 15.4027 98.9041 13.113ZM106.357 12.4345V11.8409C105.764 11.5865 105.087 11.4168 104.325 11.4168C102.969 11.4168 102.122 12.0105 102.122 13.0282V13.113C102.122 13.961 102.885 14.4698 103.901 14.4698C105.341 14.385 106.357 13.537 106.357 12.4345Z" fill="#141418"/>
      <path d="M112.201 4.97121H115.419V6.58252C116.182 5.64966 117.113 4.7168 118.807 4.7168C121.263 4.7168 122.703 6.3281 122.703 8.95708V16.3352H119.485V9.97475C119.485 8.44825 118.722 7.68499 117.537 7.68499C116.351 7.68499 115.504 8.44825 115.504 9.97475V16.3352H112.286V4.97121H112.201Z" fill="#141418"/>
      <path d="M125.498 0.816162H128.716V6.58295C129.479 5.65008 130.41 4.71722 132.104 4.71722C134.56 4.71722 136 6.32853 136 8.95751V16.3356H132.782V9.97517C132.782 8.44867 132.019 7.68542 130.834 7.68542C129.648 7.68542 128.801 8.44867 128.801 9.97517V16.3356H125.583V0.816162H125.498Z" fill="#141418"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LogoText'
};
</script>

<style lang="scss">
.logo-text {
  display: inline-block;

  svg {
    path, polygon {
      fill: currentColor;
    }
  }
}
</style>
