import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import loader from '@/components/Loader/store';
import flash from '@/components/FlashMessage/store';

import app from './app';
import blog from './blog';
import eligibleItem from './eligibleItem';

const STORE_KEY = process.env.VUE_APP_STORE_KEY;
const namespace = 'camdonhanh-website';
const key = `${namespace}.${STORE_KEY}`;

const vuexPersistLocal = createPersistedState({
  key,
  storage: window.localStorage,
  paths: ['app']
});

const vuexPersistSession = createPersistedState({
  key,
  storage: window.sessionStorage,
  paths: ['blog.tags', 'eligibleItem.items']
});

const plugins = [
  vuexPersistLocal,
  vuexPersistSession
];

const store = createStore({
  plugins,
  modules: {
    app,
    loader,
    flash,
    blog,
    eligibleItem
  }
});

export default store;
