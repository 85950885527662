<template>
  <div id="home">
    <banner />
    <articles-about-us />
    <how-we-work />
    <submit-request />
    <testimonials />
    <eligible-items />
    <assurance />
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import Banner from './Banner';
import ArticlesAboutUs from './ArticlesAboutUs';
import HowWeWork from './HowWeWork';
import SubmitRequest from './SubmitRequest';
import Testimonials from './Testimonials';
import EligibleItems from './EligibleItems';
import Assurance from './Assurance';

export default {
  name: 'Home',
  components: {
    Banner,
    ArticlesAboutUs,
    HowWeWork,
    SubmitRequest,
    Testimonials,
    EligibleItems,
    Assurance
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('CAM_DO_UY_TIN_DINH_GIA_CAO');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    return {
      t,
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#home {
  width: 100%;
}
</style>
