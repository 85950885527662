import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';

export default {
  async readDiscreteContent(key) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/discrete-content/${key}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {}, false);
      const article = processSuccessResponse(res);

      return article;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },
};
