<template>
  <div id="about-us">
    <cover />
    <about-content />
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import Cover from './Cover';
import AboutContent from './Content';

export default {
  name: 'AboutUs',
  components: {
    Cover,
    AboutContent
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('ABOUT_US');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    return {
      t,
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#about-us {
  width: 100%;
}
</style>
