import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';

export default {
  async getItems() {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: '/v1/eligible-items/',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const tags = processSuccessResponse(res);

      return tags;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },
};
