import ERROR_CODES from '@/consts/errorCodes';

export default class InvalidRefreshToken extends Error {
  static code = ERROR_CODES.AUTH_INVALID_REFRESH_TOKEN;

  constructor(message) {
    super(message || 'Invalid refresh token.');
    this.code = ERROR_CODES.AUTH_INVALID_REFRESH_TOKEN;
  }
}
