import { FLASH_TYPES as TYPES } from '@/consts';

const actions = {
  info({ commit }, msg) {
    commit('setMessage', msg);
    commit('setType', TYPES.INFO);
    commit('setActiveStatus', true);
  },

  error({ commit }, msg) {
    commit('setMessage', msg);
    commit('setType', TYPES.ERROR);
    commit('setActiveStatus', true);
  },

  setActs({ commit }, acts) {
    commit('setActs', acts);
  },

  setActiveStatus({ commit }, val) {
    commit('setActiveStatus', val);
  },

  setHovering({ commit }, val) {
    commit('setHovering', val);
  }
};

export default actions;
