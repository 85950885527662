<template>
  <div id="contacts">
    <cover />
    <branches />
    <get-in-touch />
    <stats />
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import Cover from './Cover';
import Branches from './Branches';
import GetInTouch from './GetInTouch';
import Stats from './Stats';

export default {
  name: 'Contacts',
  components: {
    Cover,
    Branches,
    GetInTouch,
    Stats
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('CONTACTS');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    return {
      t,
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#contacts {
  width: 100%;
}
</style>
