<template>
  <div id="messenger-plugin">
    <div id="fb-root" />
    <div id="fb-customer-chat" class="fb-customerchat" ref="chatRef" />
  </div>
</template>

<script>
import { computed, watch, ref, onMounted } from 'vue';

export default {
  name: 'MessengerPlugin',
  setup() {
    const chatRef = ref(null);

    const load = () => {
      if (process.env.VUE_APP_ENV === 'dev') return;
      if (!process.browser) return;

      const chatbox = chatRef.value;
      chatbox.setAttribute('page_id', '1484065851915262');
      chatbox.setAttribute('attribution', 'biz_inbox');

      setTimeout(() => {
        window.fbAsyncInit = function fbAsyncInit_() {
          window.FB.init({
            xfbml: true,
            version: 'v15.0'
          });
        };

        (function f_(d, s, id) {
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          const js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }, 0);
    };

    watch(chatRef, (chat) => {
      if (!chat) return;
      load();
    });

    return {
      chatRef
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/colors';

#meseenger-plugin {
}
</style>
