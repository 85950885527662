<template>
  <div id="get-in-touch">
    <div class="container">
      <h1>{{ t('LIEN_HE_NGAY') }}</h1>

      <div class="row contact-points">
        <div class="col col-12 col-lg-6" v-for="item in contactPoints" :key="item.text">
          <div class="contact-point-item">
            <div class="outer-box">
              <div class="inner-box">
                <div class="wrapper">
                  <div class="icon" v-html="item.icon" />
                  <div class="text">{{ t(item.text) }}</div>
                </div>
              </div>
            </div>
            <div class="contact-info">{{ t(item.contactInfo) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

const contactPoints = [
  {
    icon: '<svg width="57" height="52" viewBox="0 0 57 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M53.9993 26.6233C53.9993 11.0683 41.9293 0.5 28.4993 0.5C15.211 0.5 2.99935 10.8417 2.99935 26.7933C1.29935 27.7567 0.166016 29.57 0.166016 31.6667V37.3333C0.166016 40.45 2.71602 43 5.83268 43C7.39102 43 8.66602 41.725 8.66602 40.1667V26.5383C8.66602 15.6867 17.0243 6.195 27.876 5.88333C39.096 5.54333 48.3327 14.5533 48.3327 25.7167V45.8333H28.4993C26.941 45.8333 25.666 47.1083 25.666 48.6667C25.666 50.225 26.941 51.5 28.4993 51.5H48.3327C51.4493 51.5 53.9993 48.95 53.9993 45.8333V42.3767C55.671 41.4983 56.8327 39.77 56.8327 37.73V31.2133C56.8327 29.23 55.671 27.5017 53.9993 26.6233Z" fill="#E11F27"/><path d="M19.9993 31.6667C21.5642 31.6667 22.8327 30.3981 22.8327 28.8333C22.8327 27.2685 21.5642 26 19.9993 26C18.4345 26 17.166 27.2685 17.166 28.8333C17.166 30.3981 18.4345 31.6667 19.9993 31.6667Z" fill="#E11F27"/><path d="M36.9993 31.6667C38.5642 31.6667 39.8327 30.3981 39.8327 28.8333C39.8327 27.2685 38.5642 26 36.9993 26C35.4345 26 34.166 27.2685 34.166 28.8333C34.166 30.3981 35.4345 31.6667 36.9993 31.6667Z" fill="#E11F27"/><path d="M45.4993 23.2517C44.1393 15.1767 37.1127 9 28.641 9C20.056 9 10.8193 16.1117 11.556 27.275C18.5543 24.4133 23.8243 18.18 25.326 10.5867C29.0377 18.0383 36.6593 23.1667 45.4993 23.2517Z" fill="#E11F27"/></svg>',
    text: 'CHAM_SOC_KHACH_HANG',
    contactInfo: '1800 6246'
  },
  {
    icon: '<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M51.666 0.666626H6.33268C3.21602 0.666626 0.694349 3.21663 0.694349 6.33329L0.666016 57.3333L11.9993 46H51.666C54.7827 46 57.3327 43.45 57.3327 40.3333V6.33329C57.3327 3.21663 54.7827 0.666626 51.666 0.666626ZM14.8327 20.5H43.166C44.7243 20.5 45.9993 21.775 45.9993 23.3333C45.9993 24.8916 44.7243 26.1666 43.166 26.1666H14.8327C13.2743 26.1666 11.9993 24.8916 11.9993 23.3333C11.9993 21.775 13.2743 20.5 14.8327 20.5ZM31.8327 34.6666H14.8327C13.2743 34.6666 11.9993 33.3916 11.9993 31.8333C11.9993 30.275 13.2743 29 14.8327 29H31.8327C33.391 29 34.666 30.275 34.666 31.8333C34.666 33.3916 33.391 34.6666 31.8327 34.6666ZM43.166 17.6666H14.8327C13.2743 17.6666 11.9993 16.3916 11.9993 14.8333C11.9993 13.275 13.2743 12 14.8327 12H43.166C44.7243 12 45.9993 13.275 45.9993 14.8333C45.9993 16.3916 44.7243 17.6666 43.166 17.6666Z" fill="#E11F27"/></svg>',
    text: 'GUI_TIN_NHAN',
    contactInfo: 'Zalo ID: ICADO'
  }
];

export default {
  name: 'GetInTouch',
  components: {
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    return {
      t,
      contactPoints,
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#get-in-touch {
  width: 100%;
  padding: 90px 0;
  text-align: center;

  h1 {
    color: $geranium;
    font-weight: 700;
  }

  .container {
    max-width: 960px;
  }

  .contact-points {
    margin-top: 50px;

    .col {
      padding: 0 30px;
    }

    .contact-point-item {
      .outer-box {
        background: linear-gradient(131.83deg, #FFFAFA 0%, #FFF7F7 99.21%);
        border-radius: 16px;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        height: 0;
        margin-bottom: 40px;

        .inner-box {
          box-shadow: 0px 40px 80px #FBE4E0;
          border-radius: 14px;
          background-color: $white;
          width: 80%;
          padding-bottom: 80%;
          height: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          color: $geranium;

          .wrapper {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .icon {
              text-align: center;
              margin-bottom: 20px;
            }

            .text {
              font-weight: 700;
              font-size: 14px;
            }
          }
        }
      }

      .contact-info {
        font-weight: 700;
        font-size: 24px;
      }
    }
  }
}
</style>
