<template>
  <div id="eligible-item-content">
    <div class="container" v-html="item.content">
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';

export default {
  name: 'EligibleItemContent',
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    return {
      t,
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#eligible-item-content {
  width: 100%;
  padding: 80px 0;

  .container {
    max-width: 790px;
  }

  h1 {
    font-weight: 700;
    color: $geranium;
    margin-bottom: 30px;
  }

  h2 {
    font-weight: 700;
    color: $geranium;
    margin-bottom: 30px;
    margin-top: 50px;

    @media (max-width: 767px) {
      font-size: 28px;
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 16px;
    line-height: 30px;
  }

  img {
    max-width: 100%;
    margin-bottom: 15px;
  }
}
</style>
