<template>
  <div id="articles-about-us">
    <div class="container">
      <div class="holder">
        <slick :options="slickOptions" ref="slickRef">
          <div v-for="item in newsItems" :key="item.alt" class="slide">
            <div class="article-item">
              <a :href="item.url" :title="item.alt" target="_blank">
                <img :src="item.img" :alt="item.alt" />
              </a>
            </div>
          </div>
        </slick>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

import Slick from '@/components/Slick';

import comvn24h from '@/assets/imgs/news-outlets/24h-min.png';
import cafef from '@/assets/imgs/news-outlets/cafef-min.png';
import thanhnien from '@/assets/imgs/news-outlets/thanhnien-min.png';
import vnexpress from '@/assets/imgs/news-outlets/vnexpress-min.png';
import zing from '@/assets/imgs/news-outlets/zing-min.png';

const newsItems = [
  {
    img: comvn24h,
    url: 'http://www.24h.com.vn/thi-truong-24h/xu-huong-moi-cua-thi-truong-cam-do-online-c341a810245.html',
    alt: '“Xu hướng mới của thị trường: cầm đồ online”'
  },
  {
    img: cafef,
    url: 'http://cafef.vn/doanh-nghiep-gioi-thieu/khoi-nghiep-voi-mo-hinh-camdonhanh-truc-tuyen-20151109161217207.chn',
    alt: '“Khởi nghiệp với mô hình Camdonhanh trực tuyến”'
  },
  {
    img: thanhnien,
    url: 'http://thanhnien.vn/kinh-doanh/giai-phap-cuu-vien-cho-nguoi-dan-co-nhu-cau-tien-mat-646265.html',
    alt: '“Giải pháp ‘cứu viện’ cho người dân có nhu cầu tiền mặt”'
  },
  {
    img: vnexpress,
    url: 'http://kinhdoanh.vnexpress.net/tin-tuc/doanh-nghiep/dich-vu-cam-do-truc-tuyen-tai-viet-nam-3442183.html',
    alt: '“Dịch vụ cầm đồ trực tuyến tại Việt Nam”'
  },
  {
    img: zing,
    url: 'http://news.zing.vn/cam-do-truc-tuyen-giai-phap-moi-de-xoay-vong-tien-mat-post610708.html',
    alt: '“Cầm đồ trực tuyến - giải pháp mới để xoay vòng tiền mặt”'
  },
  {
    img: comvn24h,
    url: 'http://www.24h.com.vn/thi-truong-24h/xu-huong-moi-cua-thi-truong-cam-do-online-c341a810245.html',
    alt: '“Xu hướng mới của thị trường: cầm đồ online”'
  },
  {
    img: cafef,
    url: 'http://cafef.vn/doanh-nghiep-gioi-thieu/khoi-nghiep-voi-mo-hinh-camdonhanh-truc-tuyen-20151109161217207.chn',
    alt: '“Khởi nghiệp với mô hình Camdonhanh trực tuyến”'
  },
  {
    img: thanhnien,
    url: 'http://thanhnien.vn/kinh-doanh/giai-phap-cuu-vien-cho-nguoi-dan-co-nhu-cau-tien-mat-646265.html',
    alt: '“Giải pháp ‘cứu viện’ cho người dân có nhu cầu tiền mặt”'
  },
  {
    img: vnexpress,
    url: 'http://kinhdoanh.vnexpress.net/tin-tuc/doanh-nghiep/dich-vu-cam-do-truc-tuyen-tai-viet-nam-3442183.html',
    alt: '“Dịch vụ cầm đồ trực tuyến tại Việt Nam”'
  },
  {
    img: zing,
    url: 'http://news.zing.vn/cam-do-truc-tuyen-giai-phap-moi-de-xoay-vong-tien-mat-post610708.html',
    alt: '“Cầm đồ trực tuyến - giải pháp mới để xoay vòng tiền mặt”'
  }
];

const slickOptions = {
  arrows: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 5,
  speed: 200,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2
      }
    }
  ]
};

export default {
  name: 'ArticlesAboutUs',
  components: {
    Slick
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      newsItems,
      slickOptions
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#articles-about-us {
  .holder {
    padding: 0;
    margin: 0;

    .article-item {
      width: 90px;
      transition: all .2s ease-in-out;
      margin: auto;

      img {
        width: 100%;
      }

      &:hover {
        opacity: .7;
        transform: scale(1.1);
      }
    }
  }
}
</style>
