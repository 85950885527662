<template>
  <div id="about-us-cover">
    <div class="bg-top" />

    <div class="bg">
      <svg viewBox="0 0 1440 663" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 663V0H1440V496.562L1014.28 635.948L802.439 467.676L0 663Z" fill="#C51B21"/>
      </svg>
    </div>
    <div class="container">
      <h1>{{ t('HINH_THUC_CAM_DO_HIEN_DAI_&_KHAC_BIET') }}</h1>
      <h3>{{ t('CAMDONHANH_LA_DICH_VU_CAM_DO_CUA_THE_KI_XXI') }}</h3>

      <div class="cover-img-wrapper">
        <div class="cover-img">
          <img :src="coverImg" :alt="t('CAMDONHANH_LA_DICH_VU_CAM_DO_CUA_THE_KI_XXI')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import coverImg from '@/assets/imgs/about-us-cover-min.jpg';

export default {
  name: 'AboutUsCover',
  components: {
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('ABOUT_US');

    return {
      t,
      coverImg
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#about-us-cover {
  width: 100%;
  position: relative;
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media (max-width: 767px) {
      top: 100px;
    }
  }

  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    background-color: $lava;
  }

  .container {
    text-align: center;
    position: relative;
    z-index: 1;
    max-width: 960px;
    color: $white;
  }

  h1 {
    font-weight: 700;
    font-size: 64px;
  }

  h3 {
    font-size: 24px;
    padding: 15px 0;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 16px;
    }
  }

  .cover-img-wrapper {
    max-width: 790px;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;

    .cover-img {
      width: 100%;
      height: 0;
      padding-bottom: 62.15%;
      box-sizing: border-box;
      border-radius: 24px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
